const POINT_PARAM = ':point_id';
const USER_PARAM = ':user_id';
const PROJECT_PARAM = ':project_id';
const PID_PARAM = ':pid';

const prefix = import.meta.env.VITE_ROUTER_PREFIX;

export const ROUTE = {
  HOME: prefix + '/',
  MAP: prefix + '/map',
  OPENSTREETMAPS: prefix + '/openstreetmaps',
  PROFILE: prefix + `/user/${USER_PARAM}`,
  POINTS: prefix + '/points',
  POINTS_ADD: prefix + `/points/add`,
  POINTS_DETAIL: prefix + `/points/${POINT_PARAM}`,
  POINTS_EDIT: prefix + `/points/edit/${POINT_PARAM}`,
  POINTS_FROM_OPUS: prefix + `/points/opus/${PID_PARAM}`,
  POINTS_FROM_NGS: prefix + `/points/ngs/${PID_PARAM}`,
  POINTS_FROM_LOCAL: prefix + `/points/local/${POINT_PARAM}`,
  POINTS_PREPARE: prefix + `/points/prepare/${POINT_PARAM}`,
  USER_DETAIL: prefix + `/user/${USER_PARAM}`,
  ADMIN: prefix + '/admin',
  SYNC: prefix + '/sync',
  APPROVAL_QUEUE: prefix + '/approvalQueue',
  CACHE: prefix + '/cache',
  PROJECTS: prefix + '/projects',
  PROJECT_VIEW: prefix + `/projects/${PROJECT_PARAM}`,
  REPORTS_VIEW: prefix + `/reports`,
  BULK_UPLOAD_VIEW: prefix + '/bulk',
  UNAUTHORIZED: prefix + '/unauthorized',
  FORBIDDEN: prefix + '/forbidden',
};

export const PARAMS = {
  POINT_PARAM,
  USER_PARAM,
  PROJECT_PARAM,
  PID_PARAM,
};
