import { UUID } from 'io-ts-types';
import { Document } from 'usmart-common';

import { DB } from './index.ts';
import queue from './queue.ts';
import * as t from './types.ts';

export default (db: DB) => {
  const get = async (id: string) => await db.blobstore.get(id);
  const remove = async (id: UUID) =>
    await db.transaction('rw', db.blobstore, db.queue, db.doc, async () => {
      await db.blobstore.delete(id);
      await db.doc.delete(id);
      const queueItems = await db.queue.where({ docId: id }).toArray();
      for (const qI of queueItems) {
        await db.queue.delete(qI.key);
      }
    });
  const put = async (
    d: Document,
    b: t.Blob,
    controlPointId: UUID,
    index: number
  ) =>
    await db.transaction('rw', db.blobstore, db.queue, db.doc, async () => {
      await db.blobstore.put(b, b.key);
      await db.doc.put(d, d.id);
      await db.doc.put({ document: d, id: d.id }, d.id);
      await queue(db).push(
        `${controlPointId}-doc-${index}`,
        'uploadImage',
        d.id
      );
    });

  return {
    get,
    put,
    remove,
  };
};
