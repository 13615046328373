import { UUID } from 'io-ts-types';
import Geohash from 'latlon-geohash';
import * as R from 'ramda';
import {
  ControlPoint,
  ControlPointSafe,
  Status,
  deserialize,
  serialize,
} from 'usmart-common';

import { APIAction } from './../api/index.ts';
import { DB } from './index.ts';
import queue from './queue.ts';
import * as t from './types.ts';

const prefix = `db:control`;

/**
 *
 * @param lon Longitude in Decimal Degrees
 * @param lat Latitude in Decimal Degrees
 * @param precision Number of chars in geohash
 * @returns Geohash string
 */
const createSpatialKey = (pt: t.Point, precision?: number) =>
  Geohash.encode(pt.lat, pt.lon, precision);

/**
 *
 * @param geohash Geohash string
 * @returns Point
 */
const decodeSpatialKey = (geohash: string): t.Point =>
  [Geohash.bounds(geohash)].map(bounds => {
    const lat = (bounds.sw.lat + bounds.ne.lat) / 2;
    const lon = (bounds.sw.lon + bounds.ne.lon) / 2;
    return {
      lat,
      lon,
      err: { lat: bounds.ne.lat - lat, lon: bounds.ne.lon - lon },
    };
  })[0];

/**
 *
 * @param hash
 * @param latStep
 * @param lonStep
 * @returns
 */
const skip = (hash: string, latStep: number, lonStep: number) => {
  const lonLat = decodeSpatialKey(hash);
  return Geohash.encode(
    lonLat.lat + latStep,
    lonLat.lon + lonStep,
    hash.length
  );
};

/**
 *
 * @param hash
 * @param dir
 * @returns
 */
const adjacent = (hash: string, dir: string) => Geohash.adjacent(hash, dir);

/**
 *
 * @param ll Lower Left BBOX Point
 * @param ur Upper Right BBOX Point
 * @param precision Length of Geohash
 * @returns List of GeoHash Strings
 */
const boxes = (ll: t.Point, ur: t.Point, precision: number = 4): string[] => {
  const hashSW = Geohash.encode(ll.lat, ll.lon, precision);
  const hashNE = Geohash.encode(ur.lat, ur.lon, precision);
  const latLon = decodeSpatialKey(hashSW);

  const perLat = latLon.err === undefined ? 0 : latLon.err.lat * 2;
  const perLon = latLon.err === undefined ? 0 : latLon.err.lon * 2;

  const boxSW = Geohash.bounds(hashSW);
  const boxNE = Geohash.bounds(hashNE);

  const latStep = Math.round((boxNE.ne.lat - boxSW.sw.lat) / perLat);
  const lonStep = Math.round((boxNE.ne.lon - boxSW.sw.lon) / perLon);

  const boxes: string[] = [];
  for (let lat = 0; lat < latStep; lat++) {
    for (let lon = 0; lon < lonStep; lon++) {
      const h = skip(hashSW, lat * perLat, lon * perLon);
      boxes.push(h);
    }
  }
  return boxes;
};

export default (db: DB) => {
  /**
   * Find points within lower left and upper right bounding box
   * @param ll Lower left bounding box point
   * @param ur Upper right bounding box point
   * @param precision length of Geohash prefix to match with
   */
  const within = async (
    ll: t.Point,
    ur: t.Point,
    precision: number = 4,
    limit: number | undefined = undefined
  ) => {
    const hashes = boxes(ll, ur, precision);
    const res = hashes.map(hash => {
      let q = db.spatial.where('geohash').startsWith(hash);
      if (limit) {
        q = q.limit(limit);
      }
      return q
        .toArray()
        .then(recs => getAll(recs.map(rec => rec.docId) as UUID[]));
    });

    return Promise.all(res).then(r => r.flatMap(pt => pt));
  };

  const SEP = ':';

  /**
   * Parses an attribute, entity ID, and value to create an t.AVE key
   * @param attr Attribute name
   * @param val Value
   * @param eid Entity ID
   * @returns Key for an entity, converted to t.AVE form
   * @example aveKey('name', 'MyItem', 4) => 'name:MyItem:4'
   */
  const aveKey = (attr: string, val: string, eid: string): string =>
    `${attr}${SEP}${val.toLowerCase()}${SEP}${eid}`;

  /**
   * Converts an object into an array of t.AVE keys, one per key within the object.
   * @param eid Entity ID
   * @param o Object to convert
   * @returns Array of t.AVE keys generated from the object
   * @example aveKeys('4', { name: 'MyItem', count: 17 }) => ['name:MyItem:4', 'count:17:4']
   */
  const aveKeys = (eid: string, o: object): string[] => aveKeysRecur(eid, o);

  /**
   * Traverses down an object to generate an t.AVE key for each property within. Used internally only.
   * @param eid Entity ID
   * @param o Object to store as t.AVE
   * @param prefix Key to start with, defaults to `''`
   * @returns t.AVE keys for every key in `o`
   */
  const aveKeysRecur = (
    eid: string,
    o: object,
    prefix: string = ''
  ): string[] => {
    const ks = R.map(
      key => {
        const v: unknown = o[key as keyof object];
        if (R.is(Object, v)) {
          return aveKeysRecur(eid, v as object, prefix + key + SEP);
        }
        return aveKey(`${prefix}${key}`, v?.toString() ?? '', eid);
      },
      R.keys(o) as string[]
    );
    return R.flatten(ks);
  };

  /**
   * Generates a key in t.EAV form for the IndexedDB instance.
   * @param eid Entity ID
   * @param attr Attribute
   * @param val Value
   * @returns Database key in format `'entity:attribute:value'`
   * @example eavKey('4', 'name', 'MyItem') => '4:name:MyItem'
   */
  const eavKey = (eid: string, attr: string, val: string): string =>
    `${eid}${SEP}${attr}${SEP}${val.toLowerCase()}`;

  /**
   * Transforms an object into an array of keys in t.EAV form for the IndexedDB instance.
   * @param eid Entity ID
   * @param o Object to transform
   * @returns An array of database keys in format `'entity:attribute:value'`
   * @example eavKeys('4', { name: 'MyItem', count: 17 }) => ['4:name:MyItem', '4:count:17']
   */
  const eavKeys = (eid: string, o: object): string[] => eavKeysRecur(eid, o);

  /**
   * Helper method to recursively transform an object into t.EAV keys. Used internally only.
   * @param prefix String to start the key with
   * @param o Object to transform into t.EAV key
   * @returns An array of database keys in format `'entity:attribute:value'`
   */
  const eavKeysRecur = (prefix: string, o: object): string[] => {
    const ks = R.map(
      key => {
        const v: unknown = o[key as keyof object];
        if (R.is(Object, v)) {
          return eavKeysRecur(prefix + SEP + key, v as object);
        }
        return eavKey(prefix, key, v?.toString() ?? '');
      },
      R.keys(o) as string[]
    );
    return R.flatten(ks);
  };

  /**
   * Generates a key in t.AEV form for the IndexedDB instance.
   * @param attr Attribute name
   * @param eid Entity ID
   * @param val Value
   * @returns Key in `'attribute:entity:value'` form
   * @example aevKey('name', '4', 'MyItem') => 'name:4:MyItem'
   */
  const aevKey = (attr: string, eid: string, val: string): string =>
    `${attr}${SEP}${eid}${SEP}${val.toLowerCase()}`;

  /**
   * Traverses and converts an object to t.AEV-style keys.
   * @param eid Entity ID
   * @param o Object to convert
   * @returns Array of t.AEV keys generated from object
   * @example aevKeys('4', { name: 'MyItem', count: 17 }) => ['name:4:MyItem', 'count:4:17']
   */
  const aevKeys = (eid: string, o: object): string[] => aevKeysRecur(eid, o);

  /**
   * Helper method to traverse and convert objects to t.AEV keys, used internally only.
   * @param eid Entity ID
   * @param o Object to traverse and convert
   * @param prefix Current key prefix
   * @returns Array of t.AEV keys generated from object
   */
  const aevKeysRecur = (
    eid: string,
    o: object,
    prefix: string = ''
  ): string[] => {
    const ks = R.map(
      key => {
        const v: unknown = o[key as keyof object];
        if (R.is(Object, v)) {
          return aevKeysRecur(eid, v as object, prefix + SEP + key + SEP);
        }
        return aevKey(`${prefix}${key}`, eid, v?.toString() ?? '');
      },
      R.keys(o) as string[]
    );
    return R.flatten(ks);
  };

  // const putAev = (docId: string, key: string) => db.aev.put({ docId, key });
  // const putAve = (docId: string, key: string) => db.ave.put({ docId, key });

  /**
   * Adds a document to the database.
   * @param id Document ID
   * @param document Document
   * @returns A Dexie promise for the transaction result
   */
  const put = (
    id: UUID,
    document: object,
    apiOp?: APIAction,
    newId: string | undefined = undefined
  ) =>
    db
      .transaction(
        'rw',
        db.doc,
        db.aev,
        db.ave,
        db.spatial,
        db.queue,
        async () => {
          // add document to simple document store
          await db.doc.put({ document, id: newId ? newId : id }, id);

          // remove existing keys from the a/e/v stores
          await removeExistingKeys(id);

          // then add collections of docs to each permutation of A/E/V store.
          await db.aev.bulkPut(
            aevKeys(id, document).map(key => ({ key, docId: id }))
          );
          await db.ave.bulkPut(
            aveKeys(id, document).map(key => ({ key, docId: id }))
          );
          if (Object.hasOwn(document, 'geom')) {
            const doc = document as { geom: t.Point };
            const geohash = createSpatialKey(doc.geom, 12);
            const type = 'control_point';
            await db.spatial.put({ docId: id, geohash, type });
          }

          // add to sync queue if an api action is defined. An undefined API action represents an action that should
          //  not result in any server interaction.
          if (apiOp) {
            await db.queue.put(
              {
                id,
                key: id, //new Date().toISOString(),
                apiOp,
                docId: id,
              },
              id
            );
          }
        }
      )
      .then(() => ({ success: true, data: document }));

  /**
   * Removes all keys relating to a document from the database. Use when a document is updated
   * before adding new keys.
   * @param id ID of the document to clear keys for
   * @returns A Dexie promise for the transaction result
   */
  const removeExistingKeys = (id: string) =>
    db
      .transaction('rw', db.aev, db.ave, async () => {
        await db.aev.where('docId').equals(id).delete();
        await db.ave.where('docId').equals(id).delete();
      })
      .then(() => ({ success: true }));

  /**
   * Finds values for an attribute on an entity, requires exact matches for attribute names and entity IDs.
   * @param attr Attribute name
   * @param eid Entity ID
   * @param limit Limit
   * @returns A promise for up to $limit search results for the query as `ControlPoint`s
   */
  const findValues = (attr: string, eid: string, limit = 25) =>
    db.aev
      .where('key')
      .startsWith(`${attr.toLowerCase()}${SEP}${eid.toLowerCase()}`)
      .limit(limit)
      .toArray()
      .then(aevs => getAll(aevs.map(aev => aev.docId) as UUID[]));

  /**
   * Find entities that match a given attribute and value. Requires exact matches for attribute names and values.
   * @param attr Attribute name
   * @param value Value
   * @param limit Result limit
   * @returns A promise for up to $limit search results as `ControlPoint`s.
   */
  const findEntities = (attr: string, value: string, limit = 25) =>
    db.ave
      .where('key')
      .startsWith(R.isEmpty(attr) ? '' : `${attr}${SEP}${value}`)
      .limit(limit)
      .toArray()
      .then(aves => {
        const docIds = new Set<string>();
        aves.map(ave => ave.docId).forEach(v => docIds.add(v));
        return Array.from(docIds) as UUID[];
      })
      .then(recs => getAll(recs))
      .then(recs => recs.map(rec => rec));

  /**
   * Takes a row from the document store and returns the associated document.
   * @param row An object returned from a `get` query on the documents table
   * @returns The document contained in the row, if it exists.
   */
  const convertRow = (row: object | undefined, key: string) => {
    if (row && Object.hasOwn(row, 'document')) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const r: any = row;
      return { success: true, data: r.document };
    } else {
      return { success: false, error: `Unable to find ${key}` };
    }
  };

  /**
   * Gets a document from the document store by ID.
   * @param key Key for the document
   * @returns the document, wrapped in an object.
   */
  const get = (key: UUID) =>
    db.doc
      .get(key)
      .then(o => {
        return convertRow(o, key);
      })
      .catch(err => {
        if ('message' in err) {
          console.log(err.message);
        }
        return undefined;
      });

  /**
   * Bulk version of `get(key)`. When input a array of keys, will output a array of documents found
   * matching those keys.
   * @param keys An array of keys to search for
   * @returns A promise for an array of control points that are found from the input array.
   * Those that do not exist are omitted.
   */
  const getAll = <T = ControlPointSafe>(keys: UUID[]) =>
    db.doc.bulkGet(keys).then(objs =>
      objs
        .filter((row): row is object => row !== undefined)
        .filter(
          (row): row is { document: T } => row && Object.hasOwn(row, 'document')
        )
        .map(row => row.document)
    );

  /**
   * Deletes an object from the document store.
   * @param key Key of the document to remove
   * @param queueDeletion Whether to add the deletion to the sync queue, set to false for cache management
   * @returns A promise of the operation's result
   */
  const remove = (key: UUID, queueDeletion = true): Promise<void> =>
    db.transaction('rw', db.doc, db.queue, async () => {
      await db.doc.delete(key);
      if (queueDeletion) {
        await queue(db).push(key, 'removeControlPoint', key);
      }
    });

  const mutate = async (
    cp: ControlPoint,
    opCode: APIAction = 'createControlPoint'
  ): Promise<ControlPoint> =>
    put(
      cp.id,
      {
        ...serialize(cp),
      },
      opCode
    ).then(({ data }) => data as ControlPoint);

  const create = (cp: ControlPoint) => mutate(cp);
  const archive = (cp: ControlPoint) => mutate(cp, 'archiveControlPoint');
  const update = (cp: ControlPoint) => mutate(cp, 'updateControlPoint');

  const approve = async (cpId: UUID) => {
    const initPoint = await get(cpId).then(foundPoint => foundPoint?.data);
    const point = {
      ...initPoint,
      status: 'Approved',
    };
    return mutate(point as ControlPoint, 'approveControlPoint');
  };

  const reject = async (cpId: UUID) => {
    const initPoint = await get(cpId).then(foundPoint => foundPoint?.data);

    const point = { ...initPoint, status: 'Rejected' as Status };
    return mutate(point as ControlPoint, 'rejectControlPoint');
  };

  const read = (id: UUID): Promise<ControlPoint | undefined> =>
    get(id).then(r => {
      if (!r?.success) {
        console.warn(`${prefix}:read:${r?.error}`);
        return undefined;
      } else {
        return r.data || deserialize(r.data);
      }
    });

  const readMany = async (ids: UUID[]): Promise<ControlPoint[] | undefined> => {
    const cps: ControlPoint[] = [];
    for (const id of ids) {
      const cp = await read(id);
      if (cp) cps.push(cp);
    }
    return cps;
  };

  return {
    create,
    update,
    archive,
    approve,
    reject,
    read,
    readMany,
    remove,
    findValues,
    findEntities,
    put,
    get,
    eavKeys,
    createSpatialKey,
    decodeSpatialKey,
    adjacent,
    boxes,
    within,
    getAll,
  };
};
