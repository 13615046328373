var Lt = function(e) {
  return e._tag === "Left";
}, wt = function(e) {
  return { _tag: "Left", left: e };
}, Mt = function(e) {
  return { _tag: "Right", right: e };
}, Ft = wt, kt = Mt, M = Lt, y = /* @__PURE__ */ function() {
  var e = function(t, n) {
    return e = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(r, i) {
      r.__proto__ = i;
    } || function(r, i) {
      for (var o in i) Object.prototype.hasOwnProperty.call(i, o) && (r[o] = i[o]);
    }, e(t, n);
  };
  return function(t, n) {
    if (typeof n != "function" && n !== null)
      throw new TypeError("Class extends value " + String(n) + " is not a constructor or null");
    e(t, n);
    function r() {
      this.constructor = t;
    }
    t.prototype = n === null ? Object.create(n) : (r.prototype = n.prototype, new r());
  };
}(), te = function() {
  return te = Object.assign || function(e) {
    for (var t, n = 1, r = arguments.length; n < r; n++) {
      t = arguments[n];
      for (var i in t) Object.prototype.hasOwnProperty.call(t, i) && (e[i] = t[i]);
    }
    return e;
  }, te.apply(this, arguments);
}, ie = Ft, D = function(e, t, n) {
  return ie([{ value: e, context: t, message: n }]);
}, A = kt, v = (
  /** @class */
  function() {
    function e(t, n, r, i) {
      this.name = t, this.is = n, this.validate = r, this.encode = i, this.decode = this.decode.bind(this);
    }
    return e.prototype.pipe = function(t, n) {
      var r = this;
      return n === void 0 && (n = "pipe(".concat(this.name, ", ").concat(t.name, ")")), new e(n, t.is, function(i, o) {
        var c = r.validate(i, o);
        return M(c) ? c : t.validate(c.right, o);
      }, this.encode === S && t.encode === S ? S : function(i) {
        return r.encode(t.encode(i));
      });
    }, e.prototype.asDecoder = function() {
      return this;
    }, e.prototype.asEncoder = function() {
      return this;
    }, e.prototype.decode = function(t) {
      return this.validate(t, [{ key: "", type: this, actual: t }]);
    }, e;
  }()
), S = function(e) {
  return e;
};
function Ut(e) {
  return e.displayName || e.name || "<function".concat(e.length, ">");
}
function ne(e, t, n, r) {
  for (var i = e.length, o = Array(i + 1), c = 0; c < i; c++)
    o[c] = e[c];
  return o[i] = { key: t, type: n, actual: r }, o;
}
function fe(e, t) {
  for (var n = t.length, r = 0; r < n; r++)
    e.push(t[r]);
}
var re = Object.prototype.hasOwnProperty;
function Vt(e) {
  return Object.keys(e).map(function(t) {
    return "".concat(t, ": ").concat(e[t].name);
  }).join(", ");
}
function ve(e) {
  for (var t = 0; t < e.length; t++)
    if (e[t].encode !== S)
      return !1;
  return !0;
}
function rt(e) {
  return "{ ".concat(Vt(e), " }");
}
function jt(e) {
  return "Partial<".concat(e, ">");
}
function Bt(e) {
  return "(" + e.map(function(t) {
    return t.name;
  }).join(" | ") + ")";
}
function Ve(e, t) {
  for (var n = !0, r = !0, i = !G.is(e), o = 0, c = t; o < c.length; o++) {
    var a = c[o];
    a !== e && (n = !1), G.is(a) && (r = !1);
  }
  if (n)
    return e;
  if (r)
    return t[t.length - 1];
  for (var l = {}, d = 0, p = t; d < p.length; d++) {
    var a = p[d];
    for (var m in a)
      (!re.call(l, m) || i || a[m] !== e[m]) && (l[m] = a[m]);
  }
  return l;
}
function $t(e) {
  return e.length > 0;
}
var L = {};
function it(e, t) {
  for (var n = [], r = 0, i = e; r < i.length; r++) {
    var o = i[r];
    t.indexOf(o) !== -1 && n.push(o);
  }
  return n;
}
function Ht(e, t) {
  if (e === L)
    return t;
  if (t === L)
    return e;
  var n = Object.assign({}, e);
  for (var r in t)
    if (re.call(e, r)) {
      var i = it(e[r], t[r]);
      if ($t(i))
        n[r] = i;
      else {
        n = L;
        break;
      }
    } else
      n[r] = t[r];
  return n;
}
function Gt(e, t) {
  if (e === L || t === L)
    return L;
  var n = L;
  for (var r in e)
    if (re.call(t, r)) {
      var i = it(e[r], t[r]);
      i.length === 0 && (n === L && (n = {}), n[r] = e[r].concat(t[r]));
    }
  return n;
}
function zt(e) {
  return e._tag === "LiteralType";
}
function qt(e) {
  return e._tag === "InterfaceType";
}
function Wt(e) {
  return e._tag === "StrictType";
}
function Qt(e) {
  return e._tag === "ExactType";
}
function Yt(e) {
  return e._tag === "RefinementType";
}
function Jt(e) {
  return e._tag === "IntersectionType";
}
function Xt(e) {
  return e._tag === "UnionType";
}
function Zt(e) {
  return e._tag === "RecursiveType";
}
var Ee = [];
function W(e) {
  if (Ee.indexOf(e) !== -1)
    return L;
  if (qt(e) || Wt(e)) {
    var t = L;
    for (var n in e.props) {
      var r = e.props[n];
      zt(r) && (t === L && (t = {}), t[n] = [r.value]);
    }
    return t;
  } else {
    if (Qt(e) || Yt(e))
      return W(e.type);
    if (Jt(e))
      return e.types.reduce(function(o, c) {
        return Ht(o, W(c));
      }, L);
    if (Xt(e))
      return e.types.slice(1).reduce(function(o, c) {
        return Gt(o, W(c));
      }, W(e.types[0]));
    if (Zt(e)) {
      Ee.push(e);
      var i = W(e.type);
      return Ee.pop(), i;
    }
  }
  return L;
}
function Kt(e) {
  var t = W(e[0]), n = Object.keys(t), r = e.length, i = function(d) {
    for (var p = t[d].slice(), m = [t[d]], g = 1; g < r; g++) {
      var R = e[g], N = W(R), O = N[d];
      if (O === void 0)
        return "continue-keys";
      if (O.some(function(j) {
        return p.indexOf(j) !== -1;
      }))
        return "continue-keys";
      p.push.apply(p, O), m.push(O);
    }
    return { value: [d, m] };
  };
  e: for (var o = 0, c = n; o < c.length; o++) {
    var a = c[o], l = i(a);
    if (typeof l == "object")
      return l.value;
    switch (l) {
      case "continue-keys":
        continue e;
    }
  }
}
var en = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(this, "null", function(r) {
        return r === null;
      }, function(r, i) {
        return n.is(r) ? A(r) : D(r, i);
      }, S) || this;
      return n._tag = "NullType", n;
    }
    return t;
  }(v)
);
new en();
var tn = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(this, "undefined", function(r) {
        return r === void 0;
      }, function(r, i) {
        return n.is(r) ? A(r) : D(r, i);
      }, S) || this;
      return n._tag = "UndefinedType", n;
    }
    return t;
  }(v)
), je = new tn(), nn = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(this, "void", je.is, je.validate, S) || this;
      return n._tag = "VoidType", n;
    }
    return t;
  }(v)
);
new nn();
var rn = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(this, "unknown", function(r) {
        return !0;
      }, A, S) || this;
      return n._tag = "UnknownType", n;
    }
    return t;
  }(v)
);
new rn();
var on = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(this, "string", function(r) {
        return typeof r == "string";
      }, function(r, i) {
        return n.is(r) ? A(r) : D(r, i);
      }, S) || this;
      return n._tag = "StringType", n;
    }
    return t;
  }(v)
), s = new on(), sn = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(this, "number", function(r) {
        return typeof r == "number";
      }, function(r, i) {
        return n.is(r) ? A(r) : D(r, i);
      }, S) || this;
      return n._tag = "NumberType", n;
    }
    return t;
  }(v)
), J = new sn(), an = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(
        this,
        "bigint",
        // tslint:disable-next-line: valid-typeof
        function(r) {
          return typeof r == "bigint";
        },
        function(r, i) {
          return n.is(r) ? A(r) : D(r, i);
        },
        S
      ) || this;
      return n._tag = "BigIntType", n;
    }
    return t;
  }(v)
);
new an();
var cn = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(this, "boolean", function(r) {
        return typeof r == "boolean";
      }, function(r, i) {
        return n.is(r) ? A(r) : D(r, i);
      }, S) || this;
      return n._tag = "BooleanType", n;
    }
    return t;
  }(v)
), x = new cn(), un = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(this, "UnknownArray", Array.isArray, function(r, i) {
        return n.is(r) ? A(r) : D(r, i);
      }, S) || this;
      return n._tag = "AnyArrayType", n;
    }
    return t;
  }(v)
), Be = new un(), ln = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(this, "UnknownRecord", function(r) {
        return r !== null && typeof r == "object" && !Array.isArray(r);
      }, function(r, i) {
        return n.is(r) ? A(r) : D(r, i);
      }, S) || this;
      return n._tag = "AnyDictionaryType", n;
    }
    return t;
  }(v)
), G = new ln(), dn = (
  /** @class */
  function(e) {
    y(t, e);
    function t(n, r, i, o, c) {
      var a = e.call(this, n, r, i, o) || this;
      return a.value = c, a._tag = "LiteralType", a;
    }
    return t;
  }(v)
);
function T(e, t) {
  t === void 0 && (t = JSON.stringify(e));
  var n = function(r) {
    return r === e;
  };
  return new dn(t, n, function(r, i) {
    return n(r) ? A(e) : D(r, i);
  }, S, e);
}
var pn = (
  /** @class */
  function(e) {
    y(t, e);
    function t(n, r, i, o, c) {
      var a = e.call(this, n, r, i, o) || this;
      return a.keys = c, a._tag = "KeyofType", a;
    }
    return t;
  }(v)
);
function F(e, t) {
  t === void 0 && (t = Object.keys(e).map(function(r) {
    return JSON.stringify(r);
  }).join(" | "));
  var n = function(r) {
    return s.is(r) && re.call(e, r);
  };
  return new pn(t, n, function(r, i) {
    return n(r) ? A(r) : D(r, i);
  }, S, e);
}
var hn = (
  /** @class */
  function(e) {
    y(t, e);
    function t(n, r, i, o, c, a) {
      var l = e.call(this, n, r, i, o) || this;
      return l.type = c, l.predicate = a, l._tag = "RefinementType", l;
    }
    return t;
  }(v)
);
function ot(e, t, n) {
  return at(e, t, n);
}
ot(J, function(e) {
  return Number.isInteger(e);
}, "Int");
var fn = (
  /** @class */
  function(e) {
    y(t, e);
    function t(n, r, i, o, c) {
      var a = e.call(this, n, r, i, o) || this;
      return a.runDefinition = c, a._tag = "RecursiveType", a;
    }
    return t;
  }(v)
);
Object.defineProperty(fn.prototype, "type", {
  get: function() {
    return this.runDefinition();
  },
  enumerable: !0,
  configurable: !0
});
var mn = (
  /** @class */
  function(e) {
    y(t, e);
    function t(n, r, i, o, c) {
      var a = e.call(this, n, r, i, o) || this;
      return a.type = c, a._tag = "ArrayType", a;
    }
    return t;
  }(v)
);
function pe(e, t) {
  return t === void 0 && (t = "Array<".concat(e.name, ">")), new mn(t, function(n) {
    return Be.is(n) && n.every(e.is);
  }, function(n, r) {
    var i = Be.validate(n, r);
    if (M(i))
      return i;
    for (var o = i.right, c = o.length, a = o, l = [], d = 0; d < c; d++) {
      var p = o[d], m = e.validate(p, ne(r, String(d), e, p));
      if (M(m))
        fe(l, m.left);
      else {
        var g = m.right;
        g !== p && (a === o && (a = o.slice()), a[d] = g);
      }
    }
    return l.length > 0 ? ie(l) : A(a);
  }, e.encode === S ? S : function(n) {
    return n.map(e.encode);
  }, e);
}
var gn = (
  /** @class */
  function(e) {
    y(t, e);
    function t(n, r, i, o, c) {
      var a = e.call(this, n, r, i, o) || this;
      return a.props = c, a._tag = "InterfaceType", a;
    }
    return t;
  }(v)
);
function P(e, t) {
  t === void 0 && (t = rt(e));
  var n = Object.keys(e), r = n.map(function(o) {
    return e[o];
  }), i = n.length;
  return new gn(t, function(o) {
    if (G.is(o)) {
      for (var c = 0; c < i; c++) {
        var a = n[c], l = o[a];
        if (l === void 0 && !re.call(o, a) || !r[c].is(l))
          return !1;
      }
      return !0;
    }
    return !1;
  }, function(o, c) {
    var a = G.validate(o, c);
    if (M(a))
      return a;
    for (var l = a.right, d = l, p = [], m = 0; m < i; m++) {
      var g = n[m], R = d[g], N = r[m], O = N.validate(R, ne(c, g, N, R));
      if (M(O))
        fe(p, O.left);
      else {
        var j = O.right;
        (j !== R || j === void 0 && !re.call(d, g)) && (d === l && (d = te({}, l)), d[g] = j);
      }
    }
    return p.length > 0 ? ie(p) : A(d);
  }, ve(r) ? S : function(o) {
    for (var c = te({}, o), a = 0; a < i; a++) {
      var l = n[a], d = r[a].encode;
      d !== S && (c[l] = d(o[l]));
    }
    return c;
  }, e);
}
var yn = (
  /** @class */
  function(e) {
    y(t, e);
    function t(n, r, i, o, c) {
      var a = e.call(this, n, r, i, o) || this;
      return a.props = c, a._tag = "PartialType", a;
    }
    return t;
  }(v)
);
function V(e, t) {
  t === void 0 && (t = jt(rt(e)));
  var n = Object.keys(e), r = n.map(function(o) {
    return e[o];
  }), i = n.length;
  return new yn(t, function(o) {
    if (G.is(o)) {
      for (var c = 0; c < i; c++) {
        var a = n[c], l = o[a];
        if (l !== void 0 && !e[a].is(l))
          return !1;
      }
      return !0;
    }
    return !1;
  }, function(o, c) {
    var a = G.validate(o, c);
    if (M(a))
      return a;
    for (var l = a.right, d = l, p = [], m = 0; m < i; m++) {
      var g = n[m], R = d[g], N = e[g], O = N.validate(R, ne(c, g, N, R));
      if (M(O))
        R !== void 0 && fe(p, O.left);
      else {
        var j = O.right;
        j !== R && (d === l && (d = te({}, l)), d[g] = j);
      }
    }
    return p.length > 0 ? ie(p) : A(d);
  }, ve(r) ? S : function(o) {
    for (var c = te({}, o), a = 0; a < i; a++) {
      var l = n[a], d = o[l];
      d !== void 0 && (c[l] = r[a].encode(d));
    }
    return c;
  }, e);
}
(function(e) {
  y(t, e);
  function t(n, r, i, o, c, a) {
    var l = e.call(this, n, r, i, o) || this;
    return l.domain = c, l.codomain = a, l._tag = "DictionaryType", l;
  }
  return t;
})(v);
var st = (
  /** @class */
  function(e) {
    y(t, e);
    function t(n, r, i, o, c) {
      var a = e.call(this, n, r, i, o) || this;
      return a.types = c, a._tag = "UnionType", a;
    }
    return t;
  }(v)
);
function Y(e, t) {
  t === void 0 && (t = Bt(e));
  var n = Kt(e);
  if (n !== void 0 && e.length > 0) {
    var r = n[0], i = n[1], o = i.length, c = function(a) {
      for (var l = 0; l < o; l++)
        if (i[l].indexOf(a) !== -1)
          return l;
    };
    return new In(t, function(a) {
      if (G.is(a)) {
        var l = c(a[r]);
        return l !== void 0 ? e[l].is(a) : !1;
      }
      return !1;
    }, function(a, l) {
      var d = G.validate(a, l);
      if (M(d))
        return d;
      var p = d.right, m = c(p[r]);
      if (m === void 0)
        return D(a, l);
      var g = e[m];
      return g.validate(p, ne(l, String(m), g, p));
    }, ve(e) ? S : function(a) {
      var l = c(a[r]);
      if (l === void 0)
        throw new Error("no codec found to encode value in union codec ".concat(t));
      return e[l].encode(a);
    }, e, r);
  } else
    return new st(t, function(a) {
      return e.some(function(l) {
        return l.is(a);
      });
    }, function(a, l) {
      for (var d = [], p = 0; p < e.length; p++) {
        var m = e[p], g = m.validate(a, ne(l, String(p), m, a));
        if (M(g))
          fe(d, g.left);
        else
          return A(g.right);
      }
      return ie(d);
    }, ve(e) ? S : function(a) {
      for (var l = 0, d = e; l < d.length; l++) {
        var p = d[l];
        if (p.is(a))
          return p.encode(a);
      }
      throw new Error("no codec found to encode value in union type ".concat(t));
    }, e);
}
var vn = (
  /** @class */
  function(e) {
    y(t, e);
    function t(n, r, i, o, c) {
      var a = e.call(this, n, r, i, o) || this;
      return a.types = c, a._tag = "IntersectionType", a;
    }
    return t;
  }(v)
);
function z(e, t) {
  t === void 0 && (t = "(".concat(e.map(function(r) {
    return r.name;
  }).join(" & "), ")"));
  var n = e.length;
  return new vn(t, function(r) {
    return e.every(function(i) {
      return i.is(r);
    });
  }, e.length === 0 ? A : function(r, i) {
    for (var o = [], c = [], a = 0; a < n; a++) {
      var l = e[a], d = l.validate(r, ne(i, String(a), l, r));
      M(d) ? fe(c, d.left) : o.push(d.right);
    }
    return c.length > 0 ? ie(c) : A(Ve(r, o));
  }, e.length === 0 ? S : function(r) {
    return Ve(r, e.map(function(i) {
      return i.encode(r);
    }));
  }, e);
}
(function(e) {
  y(t, e);
  function t(n, r, i, o, c) {
    var a = e.call(this, n, r, i, o) || this;
    return a.types = c, a._tag = "TupleType", a;
  }
  return t;
})(v);
(function(e) {
  y(t, e);
  function t(n, r, i, o, c) {
    var a = e.call(this, n, r, i, o) || this;
    return a.type = c, a._tag = "ReadonlyType", a;
  }
  return t;
})(v);
(function(e) {
  y(t, e);
  function t(n, r, i, o, c) {
    var a = e.call(this, n, r, i, o) || this;
    return a.type = c, a._tag = "ReadonlyArrayType", a;
  }
  return t;
})(v);
(function(e) {
  y(t, e);
  function t(n, r, i, o, c) {
    var a = e.call(this, n, r, i, o) || this;
    return a.type = c, a._tag = "ExactType", a;
  }
  return t;
})(v);
var Sn = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(
        this,
        "Function",
        // tslint:disable-next-line:strict-type-predicates
        function(r) {
          return typeof r == "function";
        },
        function(r, i) {
          return n.is(r) ? A(r) : D(r, i);
        },
        S
      ) || this;
      return n._tag = "FunctionType", n;
    }
    return t;
  }(v)
);
new Sn();
var Tn = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(
        this,
        "never",
        function(r) {
          return !1;
        },
        function(r, i) {
          return D(r, i);
        },
        /* istanbul ignore next */
        function() {
          throw new Error("cannot encode never");
        }
      ) || this;
      return n._tag = "NeverType", n;
    }
    return t;
  }(v)
);
new Tn();
var Nn = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(this, "any", function(r) {
        return !0;
      }, A, S) || this;
      return n._tag = "AnyType", n;
    }
    return t;
  }(v)
);
new Nn();
function at(e, t, n) {
  return n === void 0 && (n = "(".concat(e.name, " | ").concat(Ut(t), ")")), new hn(n, function(r) {
    return e.is(r) && t(r);
  }, function(r, i) {
    var o = e.validate(r, i);
    if (M(o))
      return o;
    var c = o.right;
    return t(c) ? A(c) : D(c, i);
  }, e.encode, e, t);
}
at(J, Number.isInteger, "Integer");
var In = (
  /** @class */
  function(e) {
    y(t, e);
    function t(n, r, i, o, c, a) {
      var l = e.call(this, n, r, i, o, c) || this;
      return l.tag = a, l;
    }
    return t;
  }(st)
), An = (
  /** @class */
  function(e) {
    y(t, e);
    function t() {
      var n = e.call(this, "object", function(r) {
        return r !== null && typeof r == "object";
      }, function(r, i) {
        return n.is(r) ? A(r) : D(r, i);
      }, S) || this;
      return n._tag = "ObjectType", n;
    }
    return t;
  }(v)
);
new An();
(function(e) {
  y(t, e);
  function t(n, r, i, o, c) {
    var a = e.call(this, n, r, i, o) || this;
    return a.props = c, a._tag = "StrictType", a;
  }
  return t;
})(v);
function En(e, t) {
  return new v(e, t, function(n, r) {
    return t(n) ? A(n) : D(n, r);
  }, S);
}
var On = function(e) {
  return e instanceof Date;
}, Q = En("Date", On), Cn = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i, k = ot(s, function(e) {
  return Cn.test(e);
}, "UUID");
function Z(e) {
  return e != null && typeof e == "object" && e["@@functional/placeholder"] === !0;
}
function U(e) {
  return function t(n) {
    return arguments.length === 0 || Z(n) ? t : e.apply(this, arguments);
  };
}
function Me(e) {
  return function t(n, r) {
    switch (arguments.length) {
      case 0:
        return t;
      case 1:
        return Z(n) ? t : U(function(i) {
          return e(n, i);
        });
      default:
        return Z(n) && Z(r) ? t : Z(n) ? U(function(i) {
          return e(i, r);
        }) : Z(r) ? U(function(i) {
          return e(n, i);
        }) : e(n, r);
    }
  };
}
const bn = Array.isArray || function(t) {
  return t != null && t.length >= 0 && Object.prototype.toString.call(t) === "[object Array]";
};
function $e(e) {
  for (var t = [], n; !(n = e.next()).done; )
    t.push(n.value);
  return t;
}
function He(e, t, n) {
  for (var r = 0, i = n.length; r < i; ) {
    if (e(t, n[r]))
      return !0;
    r += 1;
  }
  return !1;
}
function Dn(e) {
  var t = String(e).match(/^function (\w*)/);
  return t == null ? "" : t[1];
}
function Se(e, t) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function Pn(e, t) {
  return e === t ? e !== 0 || 1 / e === 1 / t : e !== e && t !== t;
}
const Oe = typeof Object.is == "function" ? Object.is : Pn;
var Ge = Object.prototype.toString, ct = /* @__PURE__ */ function() {
  return Ge.call(arguments) === "[object Arguments]" ? function(t) {
    return Ge.call(t) === "[object Arguments]";
  } : function(t) {
    return Se("callee", t);
  };
}(), _n = !/* @__PURE__ */ {
  toString: null
}.propertyIsEnumerable("toString"), ze = ["constructor", "valueOf", "isPrototypeOf", "toString", "propertyIsEnumerable", "hasOwnProperty", "toLocaleString"], qe = /* @__PURE__ */ function() {
  return arguments.propertyIsEnumerable("length");
}(), xn = function(t, n) {
  for (var r = 0; r < t.length; ) {
    if (t[r] === n)
      return !0;
    r += 1;
  }
  return !1;
}, We = /* @__PURE__ */ U(typeof Object.keys == "function" && !qe ? function(t) {
  return Object(t) !== t ? [] : Object.keys(t);
} : function(t) {
  if (Object(t) !== t)
    return [];
  var n, r, i = [], o = qe && ct(t);
  for (n in t)
    Se(n, t) && (!o || n !== "length") && (i[i.length] = n);
  if (_n)
    for (r = ze.length - 1; r >= 0; )
      n = ze[r], Se(n, t) && !xn(i, n) && (i[i.length] = n), r -= 1;
  return i;
}), Qe = /* @__PURE__ */ U(function(t) {
  return t === null ? "Null" : t === void 0 ? "Undefined" : Object.prototype.toString.call(t).slice(8, -1);
});
function Ye(e, t, n, r) {
  var i = $e(e), o = $e(t);
  function c(a, l) {
    return Fe(a, l, n.slice(), r.slice());
  }
  return !He(function(a, l) {
    return !He(c, l, a);
  }, o, i);
}
function Fe(e, t, n, r) {
  if (Oe(e, t))
    return !0;
  var i = Qe(e);
  if (i !== Qe(t))
    return !1;
  if (typeof e["fantasy-land/equals"] == "function" || typeof t["fantasy-land/equals"] == "function")
    return typeof e["fantasy-land/equals"] == "function" && e["fantasy-land/equals"](t) && typeof t["fantasy-land/equals"] == "function" && t["fantasy-land/equals"](e);
  if (typeof e.equals == "function" || typeof t.equals == "function")
    return typeof e.equals == "function" && e.equals(t) && typeof t.equals == "function" && t.equals(e);
  switch (i) {
    case "Arguments":
    case "Array":
    case "Object":
      if (typeof e.constructor == "function" && Dn(e.constructor) === "Promise")
        return e === t;
      break;
    case "Boolean":
    case "Number":
    case "String":
      if (!(typeof e == typeof t && Oe(e.valueOf(), t.valueOf())))
        return !1;
      break;
    case "Date":
      if (!Oe(e.valueOf(), t.valueOf()))
        return !1;
      break;
    case "Error":
      return e.name === t.name && e.message === t.message;
    case "RegExp":
      if (!(e.source === t.source && e.global === t.global && e.ignoreCase === t.ignoreCase && e.multiline === t.multiline && e.sticky === t.sticky && e.unicode === t.unicode))
        return !1;
      break;
  }
  for (var o = n.length - 1; o >= 0; ) {
    if (n[o] === e)
      return r[o] === t;
    o -= 1;
  }
  switch (i) {
    case "Map":
      return e.size !== t.size ? !1 : Ye(e.entries(), t.entries(), n.concat([e]), r.concat([t]));
    case "Set":
      return e.size !== t.size ? !1 : Ye(e.values(), t.values(), n.concat([e]), r.concat([t]));
    case "Arguments":
    case "Array":
    case "Object":
    case "Boolean":
    case "Number":
    case "String":
    case "Date":
    case "Error":
    case "RegExp":
    case "Int8Array":
    case "Uint8Array":
    case "Uint8ClampedArray":
    case "Int16Array":
    case "Uint16Array":
    case "Int32Array":
    case "Uint32Array":
    case "Float32Array":
    case "Float64Array":
    case "ArrayBuffer":
      break;
    default:
      return !1;
  }
  var c = We(e);
  if (c.length !== We(t).length)
    return !1;
  var a = n.concat([e]), l = r.concat([t]);
  for (o = c.length - 1; o >= 0; ) {
    var d = c[o];
    if (!(Se(d, t) && Fe(t[d], e[d], a, l)))
      return !1;
    o -= 1;
  }
  return !0;
}
var Rn = /* @__PURE__ */ Me(function(t, n) {
  return Fe(t, n, [], []);
});
function Ln(e) {
  return Object.prototype.toString.call(e) === "[object Object]";
}
function wn(e) {
  return Object.prototype.toString.call(e) === "[object String]";
}
var se = /* @__PURE__ */ U(function(t) {
  return t == null;
});
function Mn(e) {
  var t = Object.prototype.toString.call(e);
  return t === "[object Uint8ClampedArray]" || t === "[object Int8Array]" || t === "[object Uint8Array]" || t === "[object Int16Array]" || t === "[object Uint16Array]" || t === "[object Int32Array]" || t === "[object Uint32Array]" || t === "[object Float32Array]" || t === "[object Float64Array]" || t === "[object BigInt64Array]" || t === "[object BigUint64Array]";
}
var Fn = /* @__PURE__ */ U(function(t) {
  return t != null && typeof t["fantasy-land/empty"] == "function" ? t["fantasy-land/empty"]() : t != null && t.constructor != null && typeof t.constructor["fantasy-land/empty"] == "function" ? t.constructor["fantasy-land/empty"]() : t != null && typeof t.empty == "function" ? t.empty() : t != null && t.constructor != null && typeof t.constructor.empty == "function" ? t.constructor.empty() : bn(t) ? [] : wn(t) ? "" : Ln(t) ? {} : ct(t) ? /* @__PURE__ */ function() {
    return arguments;
  }() : Mn(t) ? t.constructor.from("") : void 0;
}), Je = /* @__PURE__ */ U(function(t) {
  return t != null && Rn(t, Fn(t));
});
function Xe(e) {
  return Object.prototype.toString.call(e) === "[object Number]";
}
var ae = /* @__PURE__ */ Me(function(t, n) {
  var r = {};
  for (var i in n)
    t(n[i], i, n) && (r[i] = n[i]);
  return r;
}), Ce = /* @__PURE__ */ Me(function(t, n) {
  if (!(Xe(t) && Xe(n)))
    throw new TypeError("Both arguments to range must be numbers");
  for (var r = [], i = t; i < n; )
    r.push(i), i += 1;
  return r;
}), Te = function() {
  return Te = Object.assign || function(t) {
    for (var n, r = 1, i = arguments.length; r < i; r++) {
      n = arguments[r];
      for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && (t[o] = n[o]);
    }
    return t;
  }, Te.apply(this, arguments);
};
function be(e, t) {
  if (!!!e)
    throw new Error(t);
}
function kn(e) {
  return typeof e == "object" && e !== null;
}
function Un(e, t) {
  if (!!!e)
    throw new Error(
      "Unexpected invariant triggered."
    );
}
const Vn = /\r\n|[\n\r]/g;
function _e(e, t) {
  let n = 0, r = 1;
  for (const i of e.body.matchAll(Vn)) {
    if (typeof i.index == "number" || Un(!1), i.index >= t)
      break;
    n = i.index + i[0].length, r += 1;
  }
  return {
    line: r,
    column: t + 1 - n
  };
}
function jn(e) {
  return ut(
    e.source,
    _e(e.source, e.start)
  );
}
function ut(e, t) {
  const n = e.locationOffset.column - 1, r = "".padStart(n) + e.body, i = t.line - 1, o = e.locationOffset.line - 1, c = t.line + o, a = t.line === 1 ? n : 0, l = t.column + a, d = `${e.name}:${c}:${l}
`, p = r.split(/\r\n|[\n\r]/g), m = p[i];
  if (m.length > 120) {
    const g = Math.floor(l / 80), R = l % 80, N = [];
    for (let O = 0; O < m.length; O += 80)
      N.push(m.slice(O, O + 80));
    return d + Ze([
      [`${c} |`, N[0]],
      ...N.slice(1, g + 1).map((O) => ["|", O]),
      ["|", "^".padStart(R)],
      ["|", N[g + 1]]
    ]);
  }
  return d + Ze([
    // Lines specified like this: ["prefix", "string"],
    [`${c - 1} |`, p[i - 1]],
    [`${c} |`, m],
    ["|", "^".padStart(l)],
    [`${c + 1} |`, p[i + 1]]
  ]);
}
function Ze(e) {
  const t = e.filter(([r, i]) => i !== void 0), n = Math.max(...t.map(([r]) => r.length));
  return t.map(([r, i]) => r.padStart(n) + (i ? " " + i : "")).join(`
`);
}
function Bn(e) {
  const t = e[0];
  return t == null || "kind" in t || "length" in t ? {
    nodes: t,
    source: e[1],
    positions: e[2],
    path: e[3],
    originalError: e[4],
    extensions: e[5]
  } : t;
}
class ke extends Error {
  /**
   * An array of `{ line, column }` locations within the source GraphQL document
   * which correspond to this error.
   *
   * Errors during validation often contain multiple locations, for example to
   * point out two things with the same name. Errors during execution include a
   * single location, the field which produced the error.
   *
   * Enumerable, and appears in the result of JSON.stringify().
   */
  /**
   * An array describing the JSON-path into the execution response which
   * corresponds to this error. Only included for errors during execution.
   *
   * Enumerable, and appears in the result of JSON.stringify().
   */
  /**
   * An array of GraphQL AST Nodes corresponding to this error.
   */
  /**
   * The source GraphQL document for the first location of this error.
   *
   * Note that if this Error represents more than one node, the source may not
   * represent nodes after the first node.
   */
  /**
   * An array of character offsets within the source GraphQL document
   * which correspond to this error.
   */
  /**
   * The original error thrown from a field resolver during execution.
   */
  /**
   * Extension fields to add to the formatted error.
   */
  /**
   * @deprecated Please use the `GraphQLErrorOptions` constructor overload instead.
   */
  constructor(t, ...n) {
    var r, i, o;
    const { nodes: c, source: a, positions: l, path: d, originalError: p, extensions: m } = Bn(n);
    super(t), this.name = "GraphQLError", this.path = d ?? void 0, this.originalError = p ?? void 0, this.nodes = Ke(
      Array.isArray(c) ? c : c ? [c] : void 0
    );
    const g = Ke(
      (r = this.nodes) === null || r === void 0 ? void 0 : r.map((N) => N.loc).filter((N) => N != null)
    );
    this.source = a ?? (g == null || (i = g[0]) === null || i === void 0 ? void 0 : i.source), this.positions = l ?? (g == null ? void 0 : g.map((N) => N.start)), this.locations = l && a ? l.map((N) => _e(a, N)) : g == null ? void 0 : g.map((N) => _e(N.source, N.start));
    const R = kn(
      p == null ? void 0 : p.extensions
    ) ? p == null ? void 0 : p.extensions : void 0;
    this.extensions = (o = m ?? R) !== null && o !== void 0 ? o : /* @__PURE__ */ Object.create(null), Object.defineProperties(this, {
      message: {
        writable: !0,
        enumerable: !0
      },
      name: {
        enumerable: !1
      },
      nodes: {
        enumerable: !1
      },
      source: {
        enumerable: !1
      },
      positions: {
        enumerable: !1
      },
      originalError: {
        enumerable: !1
      }
    }), p != null && p.stack ? Object.defineProperty(this, "stack", {
      value: p.stack,
      writable: !0,
      configurable: !0
    }) : Error.captureStackTrace ? Error.captureStackTrace(this, ke) : Object.defineProperty(this, "stack", {
      value: Error().stack,
      writable: !0,
      configurable: !0
    });
  }
  get [Symbol.toStringTag]() {
    return "GraphQLError";
  }
  toString() {
    let t = this.message;
    if (this.nodes)
      for (const n of this.nodes)
        n.loc && (t += `

` + jn(n.loc));
    else if (this.source && this.locations)
      for (const n of this.locations)
        t += `

` + ut(this.source, n);
    return t;
  }
  toJSON() {
    const t = {
      message: this.message
    };
    return this.locations != null && (t.locations = this.locations), this.path != null && (t.path = this.path), this.extensions != null && Object.keys(this.extensions).length > 0 && (t.extensions = this.extensions), t;
  }
}
function Ke(e) {
  return e === void 0 || e.length === 0 ? void 0 : e;
}
function C(e, t, n) {
  return new ke(`Syntax Error: ${n}`, {
    source: e,
    positions: [t]
  });
}
class $n {
  /**
   * The character offset at which this Node begins.
   */
  /**
   * The character offset at which this Node ends.
   */
  /**
   * The Token at which this Node begins.
   */
  /**
   * The Token at which this Node ends.
   */
  /**
   * The Source document the AST represents.
   */
  constructor(t, n, r) {
    this.start = t.start, this.end = n.end, this.startToken = t, this.endToken = n, this.source = r;
  }
  get [Symbol.toStringTag]() {
    return "Location";
  }
  toJSON() {
    return {
      start: this.start,
      end: this.end
    };
  }
}
class lt {
  /**
   * The kind of Token.
   */
  /**
   * The character offset at which this Node begins.
   */
  /**
   * The character offset at which this Node ends.
   */
  /**
   * The 1-indexed line number on which this Token appears.
   */
  /**
   * The 1-indexed column number at which this Token begins.
   */
  /**
   * For non-punctuation tokens, represents the interpreted value of the token.
   *
   * Note: is undefined for punctuation tokens, but typed as string for
   * convenience in the parser.
   */
  /**
   * Tokens exist as nodes in a double-linked-list amongst all tokens
   * including ignored tokens. <SOF> is always the first node and <EOF>
   * the last.
   */
  constructor(t, n, r, i, o, c) {
    this.kind = t, this.start = n, this.end = r, this.line = i, this.column = o, this.value = c, this.prev = null, this.next = null;
  }
  get [Symbol.toStringTag]() {
    return "Token";
  }
  toJSON() {
    return {
      kind: this.kind,
      value: this.value,
      line: this.line,
      column: this.column
    };
  }
}
const Hn = {
  Name: [],
  Document: ["definitions"],
  OperationDefinition: [
    "name",
    "variableDefinitions",
    "directives",
    "selectionSet"
  ],
  VariableDefinition: ["variable", "type", "defaultValue", "directives"],
  Variable: ["name"],
  SelectionSet: ["selections"],
  Field: ["alias", "name", "arguments", "directives", "selectionSet"],
  Argument: ["name", "value"],
  FragmentSpread: ["name", "directives"],
  InlineFragment: ["typeCondition", "directives", "selectionSet"],
  FragmentDefinition: [
    "name",
    // Note: fragment variable definitions are deprecated and will removed in v17.0.0
    "variableDefinitions",
    "typeCondition",
    "directives",
    "selectionSet"
  ],
  IntValue: [],
  FloatValue: [],
  StringValue: [],
  BooleanValue: [],
  NullValue: [],
  EnumValue: [],
  ListValue: ["values"],
  ObjectValue: ["fields"],
  ObjectField: ["name", "value"],
  Directive: ["name", "arguments"],
  NamedType: ["name"],
  ListType: ["type"],
  NonNullType: ["type"],
  SchemaDefinition: ["description", "directives", "operationTypes"],
  OperationTypeDefinition: ["type"],
  ScalarTypeDefinition: ["description", "name", "directives"],
  ObjectTypeDefinition: [
    "description",
    "name",
    "interfaces",
    "directives",
    "fields"
  ],
  FieldDefinition: ["description", "name", "arguments", "type", "directives"],
  InputValueDefinition: [
    "description",
    "name",
    "type",
    "defaultValue",
    "directives"
  ],
  InterfaceTypeDefinition: [
    "description",
    "name",
    "interfaces",
    "directives",
    "fields"
  ],
  UnionTypeDefinition: ["description", "name", "directives", "types"],
  EnumTypeDefinition: ["description", "name", "directives", "values"],
  EnumValueDefinition: ["description", "name", "directives"],
  InputObjectTypeDefinition: ["description", "name", "directives", "fields"],
  DirectiveDefinition: ["description", "name", "arguments", "locations"],
  SchemaExtension: ["directives", "operationTypes"],
  ScalarTypeExtension: ["name", "directives"],
  ObjectTypeExtension: ["name", "interfaces", "directives", "fields"],
  InterfaceTypeExtension: ["name", "interfaces", "directives", "fields"],
  UnionTypeExtension: ["name", "directives", "types"],
  EnumTypeExtension: ["name", "directives", "values"],
  InputObjectTypeExtension: ["name", "directives", "fields"]
};
new Set(Object.keys(Hn));
var K;
(function(e) {
  e.QUERY = "query", e.MUTATION = "mutation", e.SUBSCRIPTION = "subscription";
})(K || (K = {}));
var xe;
(function(e) {
  e.QUERY = "QUERY", e.MUTATION = "MUTATION", e.SUBSCRIPTION = "SUBSCRIPTION", e.FIELD = "FIELD", e.FRAGMENT_DEFINITION = "FRAGMENT_DEFINITION", e.FRAGMENT_SPREAD = "FRAGMENT_SPREAD", e.INLINE_FRAGMENT = "INLINE_FRAGMENT", e.VARIABLE_DEFINITION = "VARIABLE_DEFINITION", e.SCHEMA = "SCHEMA", e.SCALAR = "SCALAR", e.OBJECT = "OBJECT", e.FIELD_DEFINITION = "FIELD_DEFINITION", e.ARGUMENT_DEFINITION = "ARGUMENT_DEFINITION", e.INTERFACE = "INTERFACE", e.UNION = "UNION", e.ENUM = "ENUM", e.ENUM_VALUE = "ENUM_VALUE", e.INPUT_OBJECT = "INPUT_OBJECT", e.INPUT_FIELD_DEFINITION = "INPUT_FIELD_DEFINITION";
})(xe || (xe = {}));
var h;
(function(e) {
  e.NAME = "Name", e.DOCUMENT = "Document", e.OPERATION_DEFINITION = "OperationDefinition", e.VARIABLE_DEFINITION = "VariableDefinition", e.SELECTION_SET = "SelectionSet", e.FIELD = "Field", e.ARGUMENT = "Argument", e.FRAGMENT_SPREAD = "FragmentSpread", e.INLINE_FRAGMENT = "InlineFragment", e.FRAGMENT_DEFINITION = "FragmentDefinition", e.VARIABLE = "Variable", e.INT = "IntValue", e.FLOAT = "FloatValue", e.STRING = "StringValue", e.BOOLEAN = "BooleanValue", e.NULL = "NullValue", e.ENUM = "EnumValue", e.LIST = "ListValue", e.OBJECT = "ObjectValue", e.OBJECT_FIELD = "ObjectField", e.DIRECTIVE = "Directive", e.NAMED_TYPE = "NamedType", e.LIST_TYPE = "ListType", e.NON_NULL_TYPE = "NonNullType", e.SCHEMA_DEFINITION = "SchemaDefinition", e.OPERATION_TYPE_DEFINITION = "OperationTypeDefinition", e.SCALAR_TYPE_DEFINITION = "ScalarTypeDefinition", e.OBJECT_TYPE_DEFINITION = "ObjectTypeDefinition", e.FIELD_DEFINITION = "FieldDefinition", e.INPUT_VALUE_DEFINITION = "InputValueDefinition", e.INTERFACE_TYPE_DEFINITION = "InterfaceTypeDefinition", e.UNION_TYPE_DEFINITION = "UnionTypeDefinition", e.ENUM_TYPE_DEFINITION = "EnumTypeDefinition", e.ENUM_VALUE_DEFINITION = "EnumValueDefinition", e.INPUT_OBJECT_TYPE_DEFINITION = "InputObjectTypeDefinition", e.DIRECTIVE_DEFINITION = "DirectiveDefinition", e.SCHEMA_EXTENSION = "SchemaExtension", e.SCALAR_TYPE_EXTENSION = "ScalarTypeExtension", e.OBJECT_TYPE_EXTENSION = "ObjectTypeExtension", e.INTERFACE_TYPE_EXTENSION = "InterfaceTypeExtension", e.UNION_TYPE_EXTENSION = "UnionTypeExtension", e.ENUM_TYPE_EXTENSION = "EnumTypeExtension", e.INPUT_OBJECT_TYPE_EXTENSION = "InputObjectTypeExtension";
})(h || (h = {}));
function Gn(e) {
  return e === 9 || e === 32;
}
function he(e) {
  return e >= 48 && e <= 57;
}
function dt(e) {
  return e >= 97 && e <= 122 || // A-Z
  e >= 65 && e <= 90;
}
function pt(e) {
  return dt(e) || e === 95;
}
function zn(e) {
  return dt(e) || he(e) || e === 95;
}
function qn(e) {
  var t;
  let n = Number.MAX_SAFE_INTEGER, r = null, i = -1;
  for (let c = 0; c < e.length; ++c) {
    var o;
    const a = e[c], l = Wn(a);
    l !== a.length && (r = (o = r) !== null && o !== void 0 ? o : c, i = c, c !== 0 && l < n && (n = l));
  }
  return e.map((c, a) => a === 0 ? c : c.slice(n)).slice(
    (t = r) !== null && t !== void 0 ? t : 0,
    i + 1
  );
}
function Wn(e) {
  let t = 0;
  for (; t < e.length && Gn(e.charCodeAt(t)); )
    ++t;
  return t;
}
var u;
(function(e) {
  e.SOF = "<SOF>", e.EOF = "<EOF>", e.BANG = "!", e.DOLLAR = "$", e.AMP = "&", e.PAREN_L = "(", e.PAREN_R = ")", e.SPREAD = "...", e.COLON = ":", e.EQUALS = "=", e.AT = "@", e.BRACKET_L = "[", e.BRACKET_R = "]", e.BRACE_L = "{", e.PIPE = "|", e.BRACE_R = "}", e.NAME = "Name", e.INT = "Int", e.FLOAT = "Float", e.STRING = "String", e.BLOCK_STRING = "BlockString", e.COMMENT = "Comment";
})(u || (u = {}));
class Qn {
  /**
   * The previously focused non-ignored token.
   */
  /**
   * The currently focused non-ignored token.
   */
  /**
   * The (1-indexed) line containing the current token.
   */
  /**
   * The character offset at which the current line begins.
   */
  constructor(t) {
    const n = new lt(u.SOF, 0, 0, 0, 0);
    this.source = t, this.lastToken = n, this.token = n, this.line = 1, this.lineStart = 0;
  }
  get [Symbol.toStringTag]() {
    return "Lexer";
  }
  /**
   * Advances the token stream to the next non-ignored token.
   */
  advance() {
    return this.lastToken = this.token, this.token = this.lookahead();
  }
  /**
   * Looks ahead and returns the next non-ignored token, but does not change
   * the state of Lexer.
   */
  lookahead() {
    let t = this.token;
    if (t.kind !== u.EOF)
      do
        if (t.next)
          t = t.next;
        else {
          const n = Jn(this, t.end);
          t.next = n, n.prev = t, t = n;
        }
      while (t.kind === u.COMMENT);
    return t;
  }
}
function Yn(e) {
  return e === u.BANG || e === u.DOLLAR || e === u.AMP || e === u.PAREN_L || e === u.PAREN_R || e === u.SPREAD || e === u.COLON || e === u.EQUALS || e === u.AT || e === u.BRACKET_L || e === u.BRACKET_R || e === u.BRACE_L || e === u.PIPE || e === u.BRACE_R;
}
function oe(e) {
  return e >= 0 && e <= 55295 || e >= 57344 && e <= 1114111;
}
function Ie(e, t) {
  return ht(e.charCodeAt(t)) && ft(e.charCodeAt(t + 1));
}
function ht(e) {
  return e >= 55296 && e <= 56319;
}
function ft(e) {
  return e >= 56320 && e <= 57343;
}
function X(e, t) {
  const n = e.source.body.codePointAt(t);
  if (n === void 0)
    return u.EOF;
  if (n >= 32 && n <= 126) {
    const r = String.fromCodePoint(n);
    return r === '"' ? `'"'` : `"${r}"`;
  }
  return "U+" + n.toString(16).toUpperCase().padStart(4, "0");
}
function E(e, t, n, r, i) {
  const o = e.line, c = 1 + n - e.lineStart;
  return new lt(t, n, r, o, c, i);
}
function Jn(e, t) {
  const n = e.source.body, r = n.length;
  let i = t;
  for (; i < r; ) {
    const o = n.charCodeAt(i);
    switch (o) {
      case 65279:
      case 9:
      case 32:
      case 44:
        ++i;
        continue;
      case 10:
        ++i, ++e.line, e.lineStart = i;
        continue;
      case 13:
        n.charCodeAt(i + 1) === 10 ? i += 2 : ++i, ++e.line, e.lineStart = i;
        continue;
      case 35:
        return Xn(e, i);
      case 33:
        return E(e, u.BANG, i, i + 1);
      case 36:
        return E(e, u.DOLLAR, i, i + 1);
      case 38:
        return E(e, u.AMP, i, i + 1);
      case 40:
        return E(e, u.PAREN_L, i, i + 1);
      case 41:
        return E(e, u.PAREN_R, i, i + 1);
      case 46:
        if (n.charCodeAt(i + 1) === 46 && n.charCodeAt(i + 2) === 46)
          return E(e, u.SPREAD, i, i + 3);
        break;
      case 58:
        return E(e, u.COLON, i, i + 1);
      case 61:
        return E(e, u.EQUALS, i, i + 1);
      case 64:
        return E(e, u.AT, i, i + 1);
      case 91:
        return E(e, u.BRACKET_L, i, i + 1);
      case 93:
        return E(e, u.BRACKET_R, i, i + 1);
      case 123:
        return E(e, u.BRACE_L, i, i + 1);
      case 124:
        return E(e, u.PIPE, i, i + 1);
      case 125:
        return E(e, u.BRACE_R, i, i + 1);
      case 34:
        return n.charCodeAt(i + 1) === 34 && n.charCodeAt(i + 2) === 34 ? rr(e, i) : Kn(e, i);
    }
    if (he(o) || o === 45)
      return Zn(e, i, o);
    if (pt(o))
      return ir(e, i);
    throw C(
      e.source,
      i,
      o === 39 ? `Unexpected single quote character ('), did you mean to use a double quote (")?` : oe(o) || Ie(n, i) ? `Unexpected character: ${X(e, i)}.` : `Invalid character: ${X(e, i)}.`
    );
  }
  return E(e, u.EOF, r, r);
}
function Xn(e, t) {
  const n = e.source.body, r = n.length;
  let i = t + 1;
  for (; i < r; ) {
    const o = n.charCodeAt(i);
    if (o === 10 || o === 13)
      break;
    if (oe(o))
      ++i;
    else if (Ie(n, i))
      i += 2;
    else
      break;
  }
  return E(
    e,
    u.COMMENT,
    t,
    i,
    n.slice(t + 1, i)
  );
}
function Zn(e, t, n) {
  const r = e.source.body;
  let i = t, o = n, c = !1;
  if (o === 45 && (o = r.charCodeAt(++i)), o === 48) {
    if (o = r.charCodeAt(++i), he(o))
      throw C(
        e.source,
        i,
        `Invalid number, unexpected digit after 0: ${X(
          e,
          i
        )}.`
      );
  } else
    i = De(e, i, o), o = r.charCodeAt(i);
  if (o === 46 && (c = !0, o = r.charCodeAt(++i), i = De(e, i, o), o = r.charCodeAt(i)), (o === 69 || o === 101) && (c = !0, o = r.charCodeAt(++i), (o === 43 || o === 45) && (o = r.charCodeAt(++i)), i = De(e, i, o), o = r.charCodeAt(i)), o === 46 || pt(o))
    throw C(
      e.source,
      i,
      `Invalid number, expected digit but got: ${X(
        e,
        i
      )}.`
    );
  return E(
    e,
    c ? u.FLOAT : u.INT,
    t,
    i,
    r.slice(t, i)
  );
}
function De(e, t, n) {
  if (!he(n))
    throw C(
      e.source,
      t,
      `Invalid number, expected digit but got: ${X(
        e,
        t
      )}.`
    );
  const r = e.source.body;
  let i = t + 1;
  for (; he(r.charCodeAt(i)); )
    ++i;
  return i;
}
function Kn(e, t) {
  const n = e.source.body, r = n.length;
  let i = t + 1, o = i, c = "";
  for (; i < r; ) {
    const a = n.charCodeAt(i);
    if (a === 34)
      return c += n.slice(o, i), E(e, u.STRING, t, i + 1, c);
    if (a === 92) {
      c += n.slice(o, i);
      const l = n.charCodeAt(i + 1) === 117 ? n.charCodeAt(i + 2) === 123 ? er(e, i) : tr(e, i) : nr(e, i);
      c += l.value, i += l.size, o = i;
      continue;
    }
    if (a === 10 || a === 13)
      break;
    if (oe(a))
      ++i;
    else if (Ie(n, i))
      i += 2;
    else
      throw C(
        e.source,
        i,
        `Invalid character within String: ${X(
          e,
          i
        )}.`
      );
  }
  throw C(e.source, i, "Unterminated string.");
}
function er(e, t) {
  const n = e.source.body;
  let r = 0, i = 3;
  for (; i < 12; ) {
    const o = n.charCodeAt(t + i++);
    if (o === 125) {
      if (i < 5 || !oe(r))
        break;
      return {
        value: String.fromCodePoint(r),
        size: i
      };
    }
    if (r = r << 4 | de(o), r < 0)
      break;
  }
  throw C(
    e.source,
    t,
    `Invalid Unicode escape sequence: "${n.slice(
      t,
      t + i
    )}".`
  );
}
function tr(e, t) {
  const n = e.source.body, r = et(n, t + 2);
  if (oe(r))
    return {
      value: String.fromCodePoint(r),
      size: 6
    };
  if (ht(r) && n.charCodeAt(t + 6) === 92 && n.charCodeAt(t + 7) === 117) {
    const i = et(n, t + 8);
    if (ft(i))
      return {
        value: String.fromCodePoint(r, i),
        size: 12
      };
  }
  throw C(
    e.source,
    t,
    `Invalid Unicode escape sequence: "${n.slice(t, t + 6)}".`
  );
}
function et(e, t) {
  return de(e.charCodeAt(t)) << 12 | de(e.charCodeAt(t + 1)) << 8 | de(e.charCodeAt(t + 2)) << 4 | de(e.charCodeAt(t + 3));
}
function de(e) {
  return e >= 48 && e <= 57 ? e - 48 : e >= 65 && e <= 70 ? e - 55 : e >= 97 && e <= 102 ? e - 87 : -1;
}
function nr(e, t) {
  const n = e.source.body;
  switch (n.charCodeAt(t + 1)) {
    case 34:
      return {
        value: '"',
        size: 2
      };
    case 92:
      return {
        value: "\\",
        size: 2
      };
    case 47:
      return {
        value: "/",
        size: 2
      };
    case 98:
      return {
        value: "\b",
        size: 2
      };
    case 102:
      return {
        value: "\f",
        size: 2
      };
    case 110:
      return {
        value: `
`,
        size: 2
      };
    case 114:
      return {
        value: "\r",
        size: 2
      };
    case 116:
      return {
        value: "	",
        size: 2
      };
  }
  throw C(
    e.source,
    t,
    `Invalid character escape sequence: "${n.slice(
      t,
      t + 2
    )}".`
  );
}
function rr(e, t) {
  const n = e.source.body, r = n.length;
  let i = e.lineStart, o = t + 3, c = o, a = "";
  const l = [];
  for (; o < r; ) {
    const d = n.charCodeAt(o);
    if (d === 34 && n.charCodeAt(o + 1) === 34 && n.charCodeAt(o + 2) === 34) {
      a += n.slice(c, o), l.push(a);
      const p = E(
        e,
        u.BLOCK_STRING,
        t,
        o + 3,
        // Return a string of the lines joined with U+000A.
        qn(l).join(`
`)
      );
      return e.line += l.length - 1, e.lineStart = i, p;
    }
    if (d === 92 && n.charCodeAt(o + 1) === 34 && n.charCodeAt(o + 2) === 34 && n.charCodeAt(o + 3) === 34) {
      a += n.slice(c, o), c = o + 1, o += 4;
      continue;
    }
    if (d === 10 || d === 13) {
      a += n.slice(c, o), l.push(a), d === 13 && n.charCodeAt(o + 1) === 10 ? o += 2 : ++o, a = "", c = o, i = o;
      continue;
    }
    if (oe(d))
      ++o;
    else if (Ie(n, o))
      o += 2;
    else
      throw C(
        e.source,
        o,
        `Invalid character within String: ${X(
          e,
          o
        )}.`
      );
  }
  throw C(e.source, o, "Unterminated string.");
}
function ir(e, t) {
  const n = e.source.body, r = n.length;
  let i = t + 1;
  for (; i < r; ) {
    const o = n.charCodeAt(i);
    if (zn(o))
      ++i;
    else
      break;
  }
  return E(
    e,
    u.NAME,
    t,
    i,
    n.slice(t, i)
  );
}
const or = 10, mt = 2;
function gt(e) {
  return Ae(e, []);
}
function Ae(e, t) {
  switch (typeof e) {
    case "string":
      return JSON.stringify(e);
    case "function":
      return e.name ? `[function ${e.name}]` : "[function]";
    case "object":
      return sr(e, t);
    default:
      return String(e);
  }
}
function sr(e, t) {
  if (e === null)
    return "null";
  if (t.includes(e))
    return "[Circular]";
  const n = [...t, e];
  if (ar(e)) {
    const r = e.toJSON();
    if (r !== e)
      return typeof r == "string" ? r : Ae(r, n);
  } else if (Array.isArray(e))
    return ur(e, n);
  return cr(e, n);
}
function ar(e) {
  return typeof e.toJSON == "function";
}
function cr(e, t) {
  const n = Object.entries(e);
  return n.length === 0 ? "{}" : t.length > mt ? "[" + lr(e) + "]" : "{ " + n.map(
    ([i, o]) => i + ": " + Ae(o, t)
  ).join(", ") + " }";
}
function ur(e, t) {
  if (e.length === 0)
    return "[]";
  if (t.length > mt)
    return "[Array]";
  const n = Math.min(or, e.length), r = e.length - n, i = [];
  for (let o = 0; o < n; ++o)
    i.push(Ae(e[o], t));
  return r === 1 ? i.push("... 1 more item") : r > 1 && i.push(`... ${r} more items`), "[" + i.join(", ") + "]";
}
function lr(e) {
  const t = Object.prototype.toString.call(e).replace(/^\[object /, "").replace(/]$/, "");
  if (t === "Object" && typeof e.constructor == "function") {
    const n = e.constructor.name;
    if (typeof n == "string" && n !== "")
      return n;
  }
  return t;
}
const dr = globalThis.process && // eslint-disable-next-line no-undef
process.env.NODE_ENV === "production", pr = (
  /* c8 ignore next 6 */
  // FIXME: https://github.com/graphql/graphql-js/issues/2317
  dr ? function(t, n) {
    return t instanceof n;
  } : function(t, n) {
    if (t instanceof n)
      return !0;
    if (typeof t == "object" && t !== null) {
      var r;
      const i = n.prototype[Symbol.toStringTag], o = (
        // We still need to support constructor's name to detect conflicts with older versions of this library.
        Symbol.toStringTag in t ? t[Symbol.toStringTag] : (r = t.constructor) === null || r === void 0 ? void 0 : r.name
      );
      if (i === o) {
        const c = gt(t);
        throw new Error(`Cannot use ${i} "${c}" from another module or realm.

Ensure that there is only one instance of "graphql" in the node_modules
directory. If different versions of "graphql" are the dependencies of other
relied on modules, use "resolutions" to ensure only one version is installed.

https://yarnpkg.com/en/docs/selective-version-resolutions

Duplicate "graphql" modules cannot be used at the same time since different
versions may have different capabilities and behavior. The data from one
version used in the function from another could produce confusing and
spurious results.`);
      }
    }
    return !1;
  }
);
class yt {
  constructor(t, n = "GraphQL request", r = {
    line: 1,
    column: 1
  }) {
    typeof t == "string" || be(!1, `Body must be a string. Received: ${gt(t)}.`), this.body = t, this.name = n, this.locationOffset = r, this.locationOffset.line > 0 || be(
      !1,
      "line in locationOffset is 1-indexed and must be positive."
    ), this.locationOffset.column > 0 || be(
      !1,
      "column in locationOffset is 1-indexed and must be positive."
    );
  }
  get [Symbol.toStringTag]() {
    return "Source";
  }
}
function hr(e) {
  return pr(e, yt);
}
function fr(e, t) {
  return new mr(e, t).parseDocument();
}
class mr {
  constructor(t, n = {}) {
    const r = hr(t) ? t : new yt(t);
    this._lexer = new Qn(r), this._options = n, this._tokenCounter = 0;
  }
  /**
   * Converts a name lex token into a name parse node.
   */
  parseName() {
    const t = this.expectToken(u.NAME);
    return this.node(t, {
      kind: h.NAME,
      value: t.value
    });
  }
  // Implements the parsing rules in the Document section.
  /**
   * Document : Definition+
   */
  parseDocument() {
    return this.node(this._lexer.token, {
      kind: h.DOCUMENT,
      definitions: this.many(
        u.SOF,
        this.parseDefinition,
        u.EOF
      )
    });
  }
  /**
   * Definition :
   *   - ExecutableDefinition
   *   - TypeSystemDefinition
   *   - TypeSystemExtension
   *
   * ExecutableDefinition :
   *   - OperationDefinition
   *   - FragmentDefinition
   *
   * TypeSystemDefinition :
   *   - SchemaDefinition
   *   - TypeDefinition
   *   - DirectiveDefinition
   *
   * TypeDefinition :
   *   - ScalarTypeDefinition
   *   - ObjectTypeDefinition
   *   - InterfaceTypeDefinition
   *   - UnionTypeDefinition
   *   - EnumTypeDefinition
   *   - InputObjectTypeDefinition
   */
  parseDefinition() {
    if (this.peek(u.BRACE_L))
      return this.parseOperationDefinition();
    const t = this.peekDescription(), n = t ? this._lexer.lookahead() : this._lexer.token;
    if (n.kind === u.NAME) {
      switch (n.value) {
        case "schema":
          return this.parseSchemaDefinition();
        case "scalar":
          return this.parseScalarTypeDefinition();
        case "type":
          return this.parseObjectTypeDefinition();
        case "interface":
          return this.parseInterfaceTypeDefinition();
        case "union":
          return this.parseUnionTypeDefinition();
        case "enum":
          return this.parseEnumTypeDefinition();
        case "input":
          return this.parseInputObjectTypeDefinition();
        case "directive":
          return this.parseDirectiveDefinition();
      }
      if (t)
        throw C(
          this._lexer.source,
          this._lexer.token.start,
          "Unexpected description, descriptions are supported only on type definitions."
        );
      switch (n.value) {
        case "query":
        case "mutation":
        case "subscription":
          return this.parseOperationDefinition();
        case "fragment":
          return this.parseFragmentDefinition();
        case "extend":
          return this.parseTypeSystemExtension();
      }
    }
    throw this.unexpected(n);
  }
  // Implements the parsing rules in the Operations section.
  /**
   * OperationDefinition :
   *  - SelectionSet
   *  - OperationType Name? VariableDefinitions? Directives? SelectionSet
   */
  parseOperationDefinition() {
    const t = this._lexer.token;
    if (this.peek(u.BRACE_L))
      return this.node(t, {
        kind: h.OPERATION_DEFINITION,
        operation: K.QUERY,
        name: void 0,
        variableDefinitions: [],
        directives: [],
        selectionSet: this.parseSelectionSet()
      });
    const n = this.parseOperationType();
    let r;
    return this.peek(u.NAME) && (r = this.parseName()), this.node(t, {
      kind: h.OPERATION_DEFINITION,
      operation: n,
      name: r,
      variableDefinitions: this.parseVariableDefinitions(),
      directives: this.parseDirectives(!1),
      selectionSet: this.parseSelectionSet()
    });
  }
  /**
   * OperationType : one of query mutation subscription
   */
  parseOperationType() {
    const t = this.expectToken(u.NAME);
    switch (t.value) {
      case "query":
        return K.QUERY;
      case "mutation":
        return K.MUTATION;
      case "subscription":
        return K.SUBSCRIPTION;
    }
    throw this.unexpected(t);
  }
  /**
   * VariableDefinitions : ( VariableDefinition+ )
   */
  parseVariableDefinitions() {
    return this.optionalMany(
      u.PAREN_L,
      this.parseVariableDefinition,
      u.PAREN_R
    );
  }
  /**
   * VariableDefinition : Variable : Type DefaultValue? Directives[Const]?
   */
  parseVariableDefinition() {
    return this.node(this._lexer.token, {
      kind: h.VARIABLE_DEFINITION,
      variable: this.parseVariable(),
      type: (this.expectToken(u.COLON), this.parseTypeReference()),
      defaultValue: this.expectOptionalToken(u.EQUALS) ? this.parseConstValueLiteral() : void 0,
      directives: this.parseConstDirectives()
    });
  }
  /**
   * Variable : $ Name
   */
  parseVariable() {
    const t = this._lexer.token;
    return this.expectToken(u.DOLLAR), this.node(t, {
      kind: h.VARIABLE,
      name: this.parseName()
    });
  }
  /**
   * ```
   * SelectionSet : { Selection+ }
   * ```
   */
  parseSelectionSet() {
    return this.node(this._lexer.token, {
      kind: h.SELECTION_SET,
      selections: this.many(
        u.BRACE_L,
        this.parseSelection,
        u.BRACE_R
      )
    });
  }
  /**
   * Selection :
   *   - Field
   *   - FragmentSpread
   *   - InlineFragment
   */
  parseSelection() {
    return this.peek(u.SPREAD) ? this.parseFragment() : this.parseField();
  }
  /**
   * Field : Alias? Name Arguments? Directives? SelectionSet?
   *
   * Alias : Name :
   */
  parseField() {
    const t = this._lexer.token, n = this.parseName();
    let r, i;
    return this.expectOptionalToken(u.COLON) ? (r = n, i = this.parseName()) : i = n, this.node(t, {
      kind: h.FIELD,
      alias: r,
      name: i,
      arguments: this.parseArguments(!1),
      directives: this.parseDirectives(!1),
      selectionSet: this.peek(u.BRACE_L) ? this.parseSelectionSet() : void 0
    });
  }
  /**
   * Arguments[Const] : ( Argument[?Const]+ )
   */
  parseArguments(t) {
    const n = t ? this.parseConstArgument : this.parseArgument;
    return this.optionalMany(u.PAREN_L, n, u.PAREN_R);
  }
  /**
   * Argument[Const] : Name : Value[?Const]
   */
  parseArgument(t = !1) {
    const n = this._lexer.token, r = this.parseName();
    return this.expectToken(u.COLON), this.node(n, {
      kind: h.ARGUMENT,
      name: r,
      value: this.parseValueLiteral(t)
    });
  }
  parseConstArgument() {
    return this.parseArgument(!0);
  }
  // Implements the parsing rules in the Fragments section.
  /**
   * Corresponds to both FragmentSpread and InlineFragment in the spec.
   *
   * FragmentSpread : ... FragmentName Directives?
   *
   * InlineFragment : ... TypeCondition? Directives? SelectionSet
   */
  parseFragment() {
    const t = this._lexer.token;
    this.expectToken(u.SPREAD);
    const n = this.expectOptionalKeyword("on");
    return !n && this.peek(u.NAME) ? this.node(t, {
      kind: h.FRAGMENT_SPREAD,
      name: this.parseFragmentName(),
      directives: this.parseDirectives(!1)
    }) : this.node(t, {
      kind: h.INLINE_FRAGMENT,
      typeCondition: n ? this.parseNamedType() : void 0,
      directives: this.parseDirectives(!1),
      selectionSet: this.parseSelectionSet()
    });
  }
  /**
   * FragmentDefinition :
   *   - fragment FragmentName on TypeCondition Directives? SelectionSet
   *
   * TypeCondition : NamedType
   */
  parseFragmentDefinition() {
    const t = this._lexer.token;
    return this.expectKeyword("fragment"), this._options.allowLegacyFragmentVariables === !0 ? this.node(t, {
      kind: h.FRAGMENT_DEFINITION,
      name: this.parseFragmentName(),
      variableDefinitions: this.parseVariableDefinitions(),
      typeCondition: (this.expectKeyword("on"), this.parseNamedType()),
      directives: this.parseDirectives(!1),
      selectionSet: this.parseSelectionSet()
    }) : this.node(t, {
      kind: h.FRAGMENT_DEFINITION,
      name: this.parseFragmentName(),
      typeCondition: (this.expectKeyword("on"), this.parseNamedType()),
      directives: this.parseDirectives(!1),
      selectionSet: this.parseSelectionSet()
    });
  }
  /**
   * FragmentName : Name but not `on`
   */
  parseFragmentName() {
    if (this._lexer.token.value === "on")
      throw this.unexpected();
    return this.parseName();
  }
  // Implements the parsing rules in the Values section.
  /**
   * Value[Const] :
   *   - [~Const] Variable
   *   - IntValue
   *   - FloatValue
   *   - StringValue
   *   - BooleanValue
   *   - NullValue
   *   - EnumValue
   *   - ListValue[?Const]
   *   - ObjectValue[?Const]
   *
   * BooleanValue : one of `true` `false`
   *
   * NullValue : `null`
   *
   * EnumValue : Name but not `true`, `false` or `null`
   */
  parseValueLiteral(t) {
    const n = this._lexer.token;
    switch (n.kind) {
      case u.BRACKET_L:
        return this.parseList(t);
      case u.BRACE_L:
        return this.parseObject(t);
      case u.INT:
        return this.advanceLexer(), this.node(n, {
          kind: h.INT,
          value: n.value
        });
      case u.FLOAT:
        return this.advanceLexer(), this.node(n, {
          kind: h.FLOAT,
          value: n.value
        });
      case u.STRING:
      case u.BLOCK_STRING:
        return this.parseStringLiteral();
      case u.NAME:
        switch (this.advanceLexer(), n.value) {
          case "true":
            return this.node(n, {
              kind: h.BOOLEAN,
              value: !0
            });
          case "false":
            return this.node(n, {
              kind: h.BOOLEAN,
              value: !1
            });
          case "null":
            return this.node(n, {
              kind: h.NULL
            });
          default:
            return this.node(n, {
              kind: h.ENUM,
              value: n.value
            });
        }
      case u.DOLLAR:
        if (t)
          if (this.expectToken(u.DOLLAR), this._lexer.token.kind === u.NAME) {
            const r = this._lexer.token.value;
            throw C(
              this._lexer.source,
              n.start,
              `Unexpected variable "$${r}" in constant value.`
            );
          } else
            throw this.unexpected(n);
        return this.parseVariable();
      default:
        throw this.unexpected();
    }
  }
  parseConstValueLiteral() {
    return this.parseValueLiteral(!0);
  }
  parseStringLiteral() {
    const t = this._lexer.token;
    return this.advanceLexer(), this.node(t, {
      kind: h.STRING,
      value: t.value,
      block: t.kind === u.BLOCK_STRING
    });
  }
  /**
   * ListValue[Const] :
   *   - [ ]
   *   - [ Value[?Const]+ ]
   */
  parseList(t) {
    const n = () => this.parseValueLiteral(t);
    return this.node(this._lexer.token, {
      kind: h.LIST,
      values: this.any(u.BRACKET_L, n, u.BRACKET_R)
    });
  }
  /**
   * ```
   * ObjectValue[Const] :
   *   - { }
   *   - { ObjectField[?Const]+ }
   * ```
   */
  parseObject(t) {
    const n = () => this.parseObjectField(t);
    return this.node(this._lexer.token, {
      kind: h.OBJECT,
      fields: this.any(u.BRACE_L, n, u.BRACE_R)
    });
  }
  /**
   * ObjectField[Const] : Name : Value[?Const]
   */
  parseObjectField(t) {
    const n = this._lexer.token, r = this.parseName();
    return this.expectToken(u.COLON), this.node(n, {
      kind: h.OBJECT_FIELD,
      name: r,
      value: this.parseValueLiteral(t)
    });
  }
  // Implements the parsing rules in the Directives section.
  /**
   * Directives[Const] : Directive[?Const]+
   */
  parseDirectives(t) {
    const n = [];
    for (; this.peek(u.AT); )
      n.push(this.parseDirective(t));
    return n;
  }
  parseConstDirectives() {
    return this.parseDirectives(!0);
  }
  /**
   * ```
   * Directive[Const] : @ Name Arguments[?Const]?
   * ```
   */
  parseDirective(t) {
    const n = this._lexer.token;
    return this.expectToken(u.AT), this.node(n, {
      kind: h.DIRECTIVE,
      name: this.parseName(),
      arguments: this.parseArguments(t)
    });
  }
  // Implements the parsing rules in the Types section.
  /**
   * Type :
   *   - NamedType
   *   - ListType
   *   - NonNullType
   */
  parseTypeReference() {
    const t = this._lexer.token;
    let n;
    if (this.expectOptionalToken(u.BRACKET_L)) {
      const r = this.parseTypeReference();
      this.expectToken(u.BRACKET_R), n = this.node(t, {
        kind: h.LIST_TYPE,
        type: r
      });
    } else
      n = this.parseNamedType();
    return this.expectOptionalToken(u.BANG) ? this.node(t, {
      kind: h.NON_NULL_TYPE,
      type: n
    }) : n;
  }
  /**
   * NamedType : Name
   */
  parseNamedType() {
    return this.node(this._lexer.token, {
      kind: h.NAMED_TYPE,
      name: this.parseName()
    });
  }
  // Implements the parsing rules in the Type Definition section.
  peekDescription() {
    return this.peek(u.STRING) || this.peek(u.BLOCK_STRING);
  }
  /**
   * Description : StringValue
   */
  parseDescription() {
    if (this.peekDescription())
      return this.parseStringLiteral();
  }
  /**
   * ```
   * SchemaDefinition : Description? schema Directives[Const]? { OperationTypeDefinition+ }
   * ```
   */
  parseSchemaDefinition() {
    const t = this._lexer.token, n = this.parseDescription();
    this.expectKeyword("schema");
    const r = this.parseConstDirectives(), i = this.many(
      u.BRACE_L,
      this.parseOperationTypeDefinition,
      u.BRACE_R
    );
    return this.node(t, {
      kind: h.SCHEMA_DEFINITION,
      description: n,
      directives: r,
      operationTypes: i
    });
  }
  /**
   * OperationTypeDefinition : OperationType : NamedType
   */
  parseOperationTypeDefinition() {
    const t = this._lexer.token, n = this.parseOperationType();
    this.expectToken(u.COLON);
    const r = this.parseNamedType();
    return this.node(t, {
      kind: h.OPERATION_TYPE_DEFINITION,
      operation: n,
      type: r
    });
  }
  /**
   * ScalarTypeDefinition : Description? scalar Name Directives[Const]?
   */
  parseScalarTypeDefinition() {
    const t = this._lexer.token, n = this.parseDescription();
    this.expectKeyword("scalar");
    const r = this.parseName(), i = this.parseConstDirectives();
    return this.node(t, {
      kind: h.SCALAR_TYPE_DEFINITION,
      description: n,
      name: r,
      directives: i
    });
  }
  /**
   * ObjectTypeDefinition :
   *   Description?
   *   type Name ImplementsInterfaces? Directives[Const]? FieldsDefinition?
   */
  parseObjectTypeDefinition() {
    const t = this._lexer.token, n = this.parseDescription();
    this.expectKeyword("type");
    const r = this.parseName(), i = this.parseImplementsInterfaces(), o = this.parseConstDirectives(), c = this.parseFieldsDefinition();
    return this.node(t, {
      kind: h.OBJECT_TYPE_DEFINITION,
      description: n,
      name: r,
      interfaces: i,
      directives: o,
      fields: c
    });
  }
  /**
   * ImplementsInterfaces :
   *   - implements `&`? NamedType
   *   - ImplementsInterfaces & NamedType
   */
  parseImplementsInterfaces() {
    return this.expectOptionalKeyword("implements") ? this.delimitedMany(u.AMP, this.parseNamedType) : [];
  }
  /**
   * ```
   * FieldsDefinition : { FieldDefinition+ }
   * ```
   */
  parseFieldsDefinition() {
    return this.optionalMany(
      u.BRACE_L,
      this.parseFieldDefinition,
      u.BRACE_R
    );
  }
  /**
   * FieldDefinition :
   *   - Description? Name ArgumentsDefinition? : Type Directives[Const]?
   */
  parseFieldDefinition() {
    const t = this._lexer.token, n = this.parseDescription(), r = this.parseName(), i = this.parseArgumentDefs();
    this.expectToken(u.COLON);
    const o = this.parseTypeReference(), c = this.parseConstDirectives();
    return this.node(t, {
      kind: h.FIELD_DEFINITION,
      description: n,
      name: r,
      arguments: i,
      type: o,
      directives: c
    });
  }
  /**
   * ArgumentsDefinition : ( InputValueDefinition+ )
   */
  parseArgumentDefs() {
    return this.optionalMany(
      u.PAREN_L,
      this.parseInputValueDef,
      u.PAREN_R
    );
  }
  /**
   * InputValueDefinition :
   *   - Description? Name : Type DefaultValue? Directives[Const]?
   */
  parseInputValueDef() {
    const t = this._lexer.token, n = this.parseDescription(), r = this.parseName();
    this.expectToken(u.COLON);
    const i = this.parseTypeReference();
    let o;
    this.expectOptionalToken(u.EQUALS) && (o = this.parseConstValueLiteral());
    const c = this.parseConstDirectives();
    return this.node(t, {
      kind: h.INPUT_VALUE_DEFINITION,
      description: n,
      name: r,
      type: i,
      defaultValue: o,
      directives: c
    });
  }
  /**
   * InterfaceTypeDefinition :
   *   - Description? interface Name Directives[Const]? FieldsDefinition?
   */
  parseInterfaceTypeDefinition() {
    const t = this._lexer.token, n = this.parseDescription();
    this.expectKeyword("interface");
    const r = this.parseName(), i = this.parseImplementsInterfaces(), o = this.parseConstDirectives(), c = this.parseFieldsDefinition();
    return this.node(t, {
      kind: h.INTERFACE_TYPE_DEFINITION,
      description: n,
      name: r,
      interfaces: i,
      directives: o,
      fields: c
    });
  }
  /**
   * UnionTypeDefinition :
   *   - Description? union Name Directives[Const]? UnionMemberTypes?
   */
  parseUnionTypeDefinition() {
    const t = this._lexer.token, n = this.parseDescription();
    this.expectKeyword("union");
    const r = this.parseName(), i = this.parseConstDirectives(), o = this.parseUnionMemberTypes();
    return this.node(t, {
      kind: h.UNION_TYPE_DEFINITION,
      description: n,
      name: r,
      directives: i,
      types: o
    });
  }
  /**
   * UnionMemberTypes :
   *   - = `|`? NamedType
   *   - UnionMemberTypes | NamedType
   */
  parseUnionMemberTypes() {
    return this.expectOptionalToken(u.EQUALS) ? this.delimitedMany(u.PIPE, this.parseNamedType) : [];
  }
  /**
   * EnumTypeDefinition :
   *   - Description? enum Name Directives[Const]? EnumValuesDefinition?
   */
  parseEnumTypeDefinition() {
    const t = this._lexer.token, n = this.parseDescription();
    this.expectKeyword("enum");
    const r = this.parseName(), i = this.parseConstDirectives(), o = this.parseEnumValuesDefinition();
    return this.node(t, {
      kind: h.ENUM_TYPE_DEFINITION,
      description: n,
      name: r,
      directives: i,
      values: o
    });
  }
  /**
   * ```
   * EnumValuesDefinition : { EnumValueDefinition+ }
   * ```
   */
  parseEnumValuesDefinition() {
    return this.optionalMany(
      u.BRACE_L,
      this.parseEnumValueDefinition,
      u.BRACE_R
    );
  }
  /**
   * EnumValueDefinition : Description? EnumValue Directives[Const]?
   */
  parseEnumValueDefinition() {
    const t = this._lexer.token, n = this.parseDescription(), r = this.parseEnumValueName(), i = this.parseConstDirectives();
    return this.node(t, {
      kind: h.ENUM_VALUE_DEFINITION,
      description: n,
      name: r,
      directives: i
    });
  }
  /**
   * EnumValue : Name but not `true`, `false` or `null`
   */
  parseEnumValueName() {
    if (this._lexer.token.value === "true" || this._lexer.token.value === "false" || this._lexer.token.value === "null")
      throw C(
        this._lexer.source,
        this._lexer.token.start,
        `${me(
          this._lexer.token
        )} is reserved and cannot be used for an enum value.`
      );
    return this.parseName();
  }
  /**
   * InputObjectTypeDefinition :
   *   - Description? input Name Directives[Const]? InputFieldsDefinition?
   */
  parseInputObjectTypeDefinition() {
    const t = this._lexer.token, n = this.parseDescription();
    this.expectKeyword("input");
    const r = this.parseName(), i = this.parseConstDirectives(), o = this.parseInputFieldsDefinition();
    return this.node(t, {
      kind: h.INPUT_OBJECT_TYPE_DEFINITION,
      description: n,
      name: r,
      directives: i,
      fields: o
    });
  }
  /**
   * ```
   * InputFieldsDefinition : { InputValueDefinition+ }
   * ```
   */
  parseInputFieldsDefinition() {
    return this.optionalMany(
      u.BRACE_L,
      this.parseInputValueDef,
      u.BRACE_R
    );
  }
  /**
   * TypeSystemExtension :
   *   - SchemaExtension
   *   - TypeExtension
   *
   * TypeExtension :
   *   - ScalarTypeExtension
   *   - ObjectTypeExtension
   *   - InterfaceTypeExtension
   *   - UnionTypeExtension
   *   - EnumTypeExtension
   *   - InputObjectTypeDefinition
   */
  parseTypeSystemExtension() {
    const t = this._lexer.lookahead();
    if (t.kind === u.NAME)
      switch (t.value) {
        case "schema":
          return this.parseSchemaExtension();
        case "scalar":
          return this.parseScalarTypeExtension();
        case "type":
          return this.parseObjectTypeExtension();
        case "interface":
          return this.parseInterfaceTypeExtension();
        case "union":
          return this.parseUnionTypeExtension();
        case "enum":
          return this.parseEnumTypeExtension();
        case "input":
          return this.parseInputObjectTypeExtension();
      }
    throw this.unexpected(t);
  }
  /**
   * ```
   * SchemaExtension :
   *  - extend schema Directives[Const]? { OperationTypeDefinition+ }
   *  - extend schema Directives[Const]
   * ```
   */
  parseSchemaExtension() {
    const t = this._lexer.token;
    this.expectKeyword("extend"), this.expectKeyword("schema");
    const n = this.parseConstDirectives(), r = this.optionalMany(
      u.BRACE_L,
      this.parseOperationTypeDefinition,
      u.BRACE_R
    );
    if (n.length === 0 && r.length === 0)
      throw this.unexpected();
    return this.node(t, {
      kind: h.SCHEMA_EXTENSION,
      directives: n,
      operationTypes: r
    });
  }
  /**
   * ScalarTypeExtension :
   *   - extend scalar Name Directives[Const]
   */
  parseScalarTypeExtension() {
    const t = this._lexer.token;
    this.expectKeyword("extend"), this.expectKeyword("scalar");
    const n = this.parseName(), r = this.parseConstDirectives();
    if (r.length === 0)
      throw this.unexpected();
    return this.node(t, {
      kind: h.SCALAR_TYPE_EXTENSION,
      name: n,
      directives: r
    });
  }
  /**
   * ObjectTypeExtension :
   *  - extend type Name ImplementsInterfaces? Directives[Const]? FieldsDefinition
   *  - extend type Name ImplementsInterfaces? Directives[Const]
   *  - extend type Name ImplementsInterfaces
   */
  parseObjectTypeExtension() {
    const t = this._lexer.token;
    this.expectKeyword("extend"), this.expectKeyword("type");
    const n = this.parseName(), r = this.parseImplementsInterfaces(), i = this.parseConstDirectives(), o = this.parseFieldsDefinition();
    if (r.length === 0 && i.length === 0 && o.length === 0)
      throw this.unexpected();
    return this.node(t, {
      kind: h.OBJECT_TYPE_EXTENSION,
      name: n,
      interfaces: r,
      directives: i,
      fields: o
    });
  }
  /**
   * InterfaceTypeExtension :
   *  - extend interface Name ImplementsInterfaces? Directives[Const]? FieldsDefinition
   *  - extend interface Name ImplementsInterfaces? Directives[Const]
   *  - extend interface Name ImplementsInterfaces
   */
  parseInterfaceTypeExtension() {
    const t = this._lexer.token;
    this.expectKeyword("extend"), this.expectKeyword("interface");
    const n = this.parseName(), r = this.parseImplementsInterfaces(), i = this.parseConstDirectives(), o = this.parseFieldsDefinition();
    if (r.length === 0 && i.length === 0 && o.length === 0)
      throw this.unexpected();
    return this.node(t, {
      kind: h.INTERFACE_TYPE_EXTENSION,
      name: n,
      interfaces: r,
      directives: i,
      fields: o
    });
  }
  /**
   * UnionTypeExtension :
   *   - extend union Name Directives[Const]? UnionMemberTypes
   *   - extend union Name Directives[Const]
   */
  parseUnionTypeExtension() {
    const t = this._lexer.token;
    this.expectKeyword("extend"), this.expectKeyword("union");
    const n = this.parseName(), r = this.parseConstDirectives(), i = this.parseUnionMemberTypes();
    if (r.length === 0 && i.length === 0)
      throw this.unexpected();
    return this.node(t, {
      kind: h.UNION_TYPE_EXTENSION,
      name: n,
      directives: r,
      types: i
    });
  }
  /**
   * EnumTypeExtension :
   *   - extend enum Name Directives[Const]? EnumValuesDefinition
   *   - extend enum Name Directives[Const]
   */
  parseEnumTypeExtension() {
    const t = this._lexer.token;
    this.expectKeyword("extend"), this.expectKeyword("enum");
    const n = this.parseName(), r = this.parseConstDirectives(), i = this.parseEnumValuesDefinition();
    if (r.length === 0 && i.length === 0)
      throw this.unexpected();
    return this.node(t, {
      kind: h.ENUM_TYPE_EXTENSION,
      name: n,
      directives: r,
      values: i
    });
  }
  /**
   * InputObjectTypeExtension :
   *   - extend input Name Directives[Const]? InputFieldsDefinition
   *   - extend input Name Directives[Const]
   */
  parseInputObjectTypeExtension() {
    const t = this._lexer.token;
    this.expectKeyword("extend"), this.expectKeyword("input");
    const n = this.parseName(), r = this.parseConstDirectives(), i = this.parseInputFieldsDefinition();
    if (r.length === 0 && i.length === 0)
      throw this.unexpected();
    return this.node(t, {
      kind: h.INPUT_OBJECT_TYPE_EXTENSION,
      name: n,
      directives: r,
      fields: i
    });
  }
  /**
   * ```
   * DirectiveDefinition :
   *   - Description? directive @ Name ArgumentsDefinition? `repeatable`? on DirectiveLocations
   * ```
   */
  parseDirectiveDefinition() {
    const t = this._lexer.token, n = this.parseDescription();
    this.expectKeyword("directive"), this.expectToken(u.AT);
    const r = this.parseName(), i = this.parseArgumentDefs(), o = this.expectOptionalKeyword("repeatable");
    this.expectKeyword("on");
    const c = this.parseDirectiveLocations();
    return this.node(t, {
      kind: h.DIRECTIVE_DEFINITION,
      description: n,
      name: r,
      arguments: i,
      repeatable: o,
      locations: c
    });
  }
  /**
   * DirectiveLocations :
   *   - `|`? DirectiveLocation
   *   - DirectiveLocations | DirectiveLocation
   */
  parseDirectiveLocations() {
    return this.delimitedMany(u.PIPE, this.parseDirectiveLocation);
  }
  /*
   * DirectiveLocation :
   *   - ExecutableDirectiveLocation
   *   - TypeSystemDirectiveLocation
   *
   * ExecutableDirectiveLocation : one of
   *   `QUERY`
   *   `MUTATION`
   *   `SUBSCRIPTION`
   *   `FIELD`
   *   `FRAGMENT_DEFINITION`
   *   `FRAGMENT_SPREAD`
   *   `INLINE_FRAGMENT`
   *
   * TypeSystemDirectiveLocation : one of
   *   `SCHEMA`
   *   `SCALAR`
   *   `OBJECT`
   *   `FIELD_DEFINITION`
   *   `ARGUMENT_DEFINITION`
   *   `INTERFACE`
   *   `UNION`
   *   `ENUM`
   *   `ENUM_VALUE`
   *   `INPUT_OBJECT`
   *   `INPUT_FIELD_DEFINITION`
   */
  parseDirectiveLocation() {
    const t = this._lexer.token, n = this.parseName();
    if (Object.prototype.hasOwnProperty.call(xe, n.value))
      return n;
    throw this.unexpected(t);
  }
  // Core parsing utility functions
  /**
   * Returns a node that, if configured to do so, sets a "loc" field as a
   * location object, used to identify the place in the source that created a
   * given parsed object.
   */
  node(t, n) {
    return this._options.noLocation !== !0 && (n.loc = new $n(
      t,
      this._lexer.lastToken,
      this._lexer.source
    )), n;
  }
  /**
   * Determines if the next token is of a given kind
   */
  peek(t) {
    return this._lexer.token.kind === t;
  }
  /**
   * If the next token is of the given kind, return that token after advancing the lexer.
   * Otherwise, do not change the parser state and throw an error.
   */
  expectToken(t) {
    const n = this._lexer.token;
    if (n.kind === t)
      return this.advanceLexer(), n;
    throw C(
      this._lexer.source,
      n.start,
      `Expected ${vt(t)}, found ${me(n)}.`
    );
  }
  /**
   * If the next token is of the given kind, return "true" after advancing the lexer.
   * Otherwise, do not change the parser state and return "false".
   */
  expectOptionalToken(t) {
    return this._lexer.token.kind === t ? (this.advanceLexer(), !0) : !1;
  }
  /**
   * If the next token is a given keyword, advance the lexer.
   * Otherwise, do not change the parser state and throw an error.
   */
  expectKeyword(t) {
    const n = this._lexer.token;
    if (n.kind === u.NAME && n.value === t)
      this.advanceLexer();
    else
      throw C(
        this._lexer.source,
        n.start,
        `Expected "${t}", found ${me(n)}.`
      );
  }
  /**
   * If the next token is a given keyword, return "true" after advancing the lexer.
   * Otherwise, do not change the parser state and return "false".
   */
  expectOptionalKeyword(t) {
    const n = this._lexer.token;
    return n.kind === u.NAME && n.value === t ? (this.advanceLexer(), !0) : !1;
  }
  /**
   * Helper function for creating an error when an unexpected lexed token is encountered.
   */
  unexpected(t) {
    const n = t ?? this._lexer.token;
    return C(
      this._lexer.source,
      n.start,
      `Unexpected ${me(n)}.`
    );
  }
  /**
   * Returns a possibly empty list of parse nodes, determined by the parseFn.
   * This list begins with a lex token of openKind and ends with a lex token of closeKind.
   * Advances the parser to the next lex token after the closing token.
   */
  any(t, n, r) {
    this.expectToken(t);
    const i = [];
    for (; !this.expectOptionalToken(r); )
      i.push(n.call(this));
    return i;
  }
  /**
   * Returns a list of parse nodes, determined by the parseFn.
   * It can be empty only if open token is missing otherwise it will always return non-empty list
   * that begins with a lex token of openKind and ends with a lex token of closeKind.
   * Advances the parser to the next lex token after the closing token.
   */
  optionalMany(t, n, r) {
    if (this.expectOptionalToken(t)) {
      const i = [];
      do
        i.push(n.call(this));
      while (!this.expectOptionalToken(r));
      return i;
    }
    return [];
  }
  /**
   * Returns a non-empty list of parse nodes, determined by the parseFn.
   * This list begins with a lex token of openKind and ends with a lex token of closeKind.
   * Advances the parser to the next lex token after the closing token.
   */
  many(t, n, r) {
    this.expectToken(t);
    const i = [];
    do
      i.push(n.call(this));
    while (!this.expectOptionalToken(r));
    return i;
  }
  /**
   * Returns a non-empty list of parse nodes, determined by the parseFn.
   * This list may begin with a lex token of delimiterKind followed by items separated by lex tokens of tokenKind.
   * Advances the parser to the next lex token after last item in the list.
   */
  delimitedMany(t, n) {
    this.expectOptionalToken(t);
    const r = [];
    do
      r.push(n.call(this));
    while (this.expectOptionalToken(t));
    return r;
  }
  advanceLexer() {
    const { maxTokens: t } = this._options, n = this._lexer.advance();
    if (t !== void 0 && n.kind !== u.EOF && (++this._tokenCounter, this._tokenCounter > t))
      throw C(
        this._lexer.source,
        n.start,
        `Document contains more that ${t} tokens. Parsing aborted.`
      );
  }
}
function me(e) {
  const t = e.value;
  return vt(e.kind) + (t != null ? ` "${t}"` : "");
}
function vt(e) {
  return Yn(e) ? `"${e}"` : e;
}
var ye = /* @__PURE__ */ new Map(), Re = /* @__PURE__ */ new Map(), St = !0, Ne = !1;
function Tt(e) {
  return e.replace(/[\s,]+/g, " ").trim();
}
function gr(e) {
  return Tt(e.source.body.substring(e.start, e.end));
}
function yr(e) {
  var t = /* @__PURE__ */ new Set(), n = [];
  return e.definitions.forEach(function(r) {
    if (r.kind === "FragmentDefinition") {
      var i = r.name.value, o = gr(r.loc), c = Re.get(i);
      c && !c.has(o) ? St && console.warn("Warning: fragment with name " + i + ` already exists.
graphql-tag enforces all fragment names across your application to be unique; read more about
this in the docs: http://dev.apollodata.com/core/fragments.html#unique-names`) : c || Re.set(i, c = /* @__PURE__ */ new Set()), c.add(o), t.has(o) || (t.add(o), n.push(r));
    } else
      n.push(r);
  }), Te(Te({}, e), { definitions: n });
}
function vr(e) {
  var t = new Set(e.definitions);
  t.forEach(function(r) {
    r.loc && delete r.loc, Object.keys(r).forEach(function(i) {
      var o = r[i];
      o && typeof o == "object" && t.add(o);
    });
  });
  var n = e.loc;
  return n && (delete n.startToken, delete n.endToken), e;
}
function Sr(e) {
  var t = Tt(e);
  if (!ye.has(t)) {
    var n = fr(e, {
      experimentalFragmentVariables: Ne,
      allowLegacyFragmentVariables: Ne
    });
    if (!n || n.kind !== "Document")
      throw new Error("Not a valid GraphQL document.");
    ye.set(t, vr(yr(n)));
  }
  return ye.get(t);
}
function f(e) {
  for (var t = [], n = 1; n < arguments.length; n++)
    t[n - 1] = arguments[n];
  typeof e == "string" && (e = [e]);
  var r = e[0];
  return t.forEach(function(i, o) {
    i && i.kind === "Document" ? r += i.loc.source.body : r += i, r += e[o + 1];
  }), Sr(r);
}
function Tr() {
  ye.clear(), Re.clear();
}
function Nr() {
  St = !1;
}
function Ir() {
  Ne = !0;
}
function Ar() {
  Ne = !1;
}
var ce = {
  gql: f,
  resetCaches: Tr,
  disableFragmentWarnings: Nr,
  enableExperimentalFragmentVariables: Ir,
  disableExperimentalFragmentVariables: Ar
};
(function(e) {
  e.gql = ce.gql, e.resetCaches = ce.resetCaches, e.disableFragmentWarnings = ce.disableFragmentWarnings, e.enableExperimentalFragmentVariables = ce.enableExperimentalFragmentVariables, e.disableExperimentalFragmentVariables = ce.disableExperimentalFragmentVariables;
})(f || (f = {}));
f.default = f;
const Er = (e) => ({
  mutation: f`
    mutation Mutation($controlPointAssociation: InputControlPointAssociation) {
      CreateControlPointAssociation(
        controlPointAssociation: $controlPointAssociation
      ) {
        primaryId
        localId
        comments
      }
    }
  `,
  variables: {
    controlPointAssociation: e
  }
}), Or = (e, t) => ({
  mutation: f`
    mutation Mutation($localId: String, $primaryIds: [String]) {
      AssociationUpdateControlPointAssociations(
        localId: $localId
        primaryIds: $primaryIds
      ) {
        primaryId
        localId
      }
    }
  `,
  variables: {
    localId: e,
    primaryIds: t
  }
}), Cr = () => ({
  query: f`
    query Query {
      ControlPointAssociations {
        primaryId
        localId
      }
    }
  `
}), br = (e, t) => ({
  query: f`
    query Query($primaries: [String], $locals: [String]) {
      ControlPointAssociationsMany(primaries: $primaries, locals: $locals) {
        primaryId
        localId
      }
    }
  `,
  variables: {
    primaries: e,
    locals: t
  }
}), Dr = (e) => ({
  query: f`
    query Query($primaryId: String!) {
      ControlPointAssociation(primaryId: $primaryId) {
        primaryId
        localId
        comments
      }
    }
  `,
  variables: {
    primaryId: e
  }
}), Pr = (e) => ({
  query: f`
    query Query($localId: String!) {
      ControlPointAssociationByLocal(localId: $localId) {
        primaryId
        localId
        comments
      }
    }
  `,
  variables: {
    localId: e
  }
}), _r = {
  createAssociation: Er,
  updateAssociations: Or,
  getMany: br,
  getAll: Cr,
  get: Dr,
  getByLocal: Pr
}, xr = (e, t, n) => ({
  mutation: f`
    mutation Mutation(
      $createControlPointCp: InputControlPoint
      $type: NSRSType
      $pid: String
    ) {
      CreateControlPointFromNSRS(
        cp: $createControlPointCp
        type: $type
        pid: $pid
      ) {
        nsrsPid
        status
        id
        coePid
        fiuoType
        type
        designation
        stamping
        setting
        condition
        stability
        comments
        legacyData
        dateLineage
        userLineage
        dateObservedVertical
        dateObservedHorizontal
        dateRecovered
        userRecovered
        dateSubmitted
        userSubmitted
        localCoordinates
        vertical {
          surveyComputationalMethod
          accuracy
          accuracyNSRSVertical
          epoch
          geoid
          datum
          units
          orthometricHeight
          ellipsoidalHeight
        }
        owner
        isBoundaryMonument
        isMagnetic
        isGPSSuitable
        physicalAccess
        isObstructedNorth
        isObstructedSouth
        isObstructedEast
        isObstructedWest
        district
        armyInstallation
        state
        county
        trs
        nearestTown
        nearestHighway
        usgsQuad
        horizontal {
          surveyComputationalMethod
          accuracyNSRSHorizontal
          accuracy
          epoch
          datum
          hemisphereEW
          hemisphereNS
          latitude {
            degrees
            minutes
            seconds
          }
          longitude {
            degrees
            minutes
            seconds
          }
        }
        point {
          type
          coordinates
        }
      }
    }
  `,
  variables: {
    createControlPointCp: Ue(e),
    type: t,
    pid: n
  }
}), Rr = (e) => ({
  mutation: f`
    mutation Mutation($createControlPointCp: InputControlPoint) {
      CreateControlPoint(cp: $createControlPointCp) {
        nsrsPid
        status
        id
        coePid
        fiuoType
        type
        designation
        stamping
        setting
        condition
        stability
        comments
        legacyData
        dateLineage
        userLineage
        dateObservedVertical
        dateObservedHorizontal
        dateRecovered
        userRecovered
        dateSubmitted
        userSubmitted
        localCoordinates
        vertical {
          surveyComputationalMethod
          accuracy
          accuracyNSRSVertical
          epoch
          geoid
          datum
          units
          orthometricHeight
          ellipsoidalHeight
        }
        owner
        isBoundaryMonument
        isMagnetic
        isGPSSuitable
        physicalAccess
        isObstructedNorth
        isObstructedSouth
        isObstructedEast
        isObstructedWest
        district
        armyInstallation
        state
        county
        trs
        nearestTown
        nearestHighway
        usgsQuad
        horizontal {
          surveyComputationalMethod
          accuracyNSRSHorizontal
          accuracy
          epoch
          datum
          hemisphereEW
          hemisphereNS
          latitude {
            degrees
            minutes
            seconds
          }
          longitude {
            degrees
            minutes
            seconds
          }
        }
        point {
          type
          coordinates
        }
      }
    }
  `,
  variables: { createControlPointCp: Ue(e) }
}), Lr = (e) => ({
  mutation: f`
    mutation Mutation($updateControlPointCp: InputControlPoint) {
      UpdateControlPoint(cp: $updateControlPointCp) {
        nsrsPid
        status
        id
        coePid
        fiuoType
        type
        designation
        stamping
        setting
        condition
        stability
        comments
        legacyData
        dateLineage
        userLineage
        dateObservedVertical
        dateObservedHorizontal
        dateRecovered
        userRecovered
        dateSubmitted
        userSubmitted
        localCoordinates
        vertical {
          surveyComputationalMethod
          accuracy
          epoch
          datum
          units
          orthometricHeight
          ellipsoidalHeight
        }
        owner
        isBoundaryMonument
        isMagnetic
        isGPSSuitable
        physicalAccess
        isObstructedNorth
        isObstructedSouth
        isObstructedEast
        isObstructedWest
        district
        armyInstallation
        state
        county
        trs
        nearestTown
        nearestHighway
        usgsQuad
        horizontal {
          surveyComputationalMethod
          accuracyNSRSHorizontal
          accuracy
          epoch
          datum
          hemisphereEW
          hemisphereNS
          latitude {
            degrees
            minutes
            seconds
          }
          longitude {
            degrees
            minutes
            seconds
          }
        }
        point {
          type
          coordinates
        }
      }
    }
  `,
  variables: { updateControlPointCp: Ue(e) }
}), wr = (e) => ({
  mutation: f`
    mutation Mutation($id: ID) {
      ArchiveControlPoint(id: $id) {
        nsrsPid
        status
        id
        coePid
        fiuoType
        type
        designation
        stamping
        setting
        condition
        stability
        comments
        legacyData
        dateLineage
        userLineage
        dateObservedVertical
        dateObservedHorizontal
        dateRecovered
        userRecovered
        dateSubmitted
        userSubmitted
        localCoordinates
        vertical {
          surveyComputationalMethod
          accuracy
          epoch
          datum
          units
          orthometricHeight
          ellipsoidalHeight
        }
        owner
        isBoundaryMonument
        isMagnetic
        isGPSSuitable
        physicalAccess
        isObstructedNorth
        isObstructedSouth
        isObstructedEast
        isObstructedWest
        district
        armyInstallation
        state
        county
        trs
        nearestTown
        nearestHighway
        usgsQuad
        horizontal {
          surveyComputationalMethod
          accuracyNSRSHorizontal
          accuracy
          epoch
          datum
          hemisphereEW
          hemisphereNS
          latitude {
            degrees
            minutes
            seconds
          }
          longitude {
            degrees
            minutes
            seconds
          }
        }
        point {
          type
          coordinates
        }
      }
    }
  `,
  variables: { id: e }
}), Mr = (e) => ({
  query: f`
    query Query($controlPointId: String!) {
      ControlPoint(id: $controlPointId) {
        nsrsPid
        status
        id
        coePid
        fiuoType
        type
        designation
        stamping
        setting
        condition
        stability
        comments
        legacyData
        dateLineage
        userLineage
        dateObservedVertical
        dateObservedHorizontal
        dateRecovered
        userRecovered
        dateSubmitted
        userSubmitted
        localCoordinates
        vertical {
          surveyComputationalMethod
          accuracy
          accuracyNSRSVertical
          epoch
          geoid
          datum
          units
          orthometricHeight
          ellipsoidalHeight
        }
        owner
        isBoundaryMonument
        isMagnetic
        isGPSSuitable
        physicalAccess
        isObstructedNorth
        isObstructedSouth
        isObstructedEast
        isObstructedWest
        district
        armyInstallation
        state
        county
        trs
        nearestTown
        nearestHighway
        usgsQuad
        horizontal {
          surveyComputationalMethod
          accuracyNSRSHorizontal
          accuracy
          epoch
          datum
          hemisphereEW
          hemisphereNS
          latitude {
            degrees
            minutes
            seconds
          }
          longitude {
            degrees
            minutes
            seconds
          }
        }
        point {
          type
          coordinates
        }
        gage {
          id
          siteId
          name
          owner
          elevation
          datum
          epoch
          comments
        }
        documents {
          id
          name
          type
          description
          mimeType
          url
          comments
        }
        projects {
          id
          name
          isCPN
          district
          cpnId
        }
      }
    }
  `,
  variables: {
    controlPointId: e
  }
}), Fr = (e, t) => ({
  query: f`
    query Query($coePid: String!, $versions: Int) {
      ControlPointVersions(coePid: $coePid, versions: $versions) {
        nsrsPid
        status
        id
        coePid
        fiuoType
        type
        designation
        stamping
        setting
        condition
        stability
        comments
        legacyData
        dateLineage
        userLineage
        dateObservedVertical
        dateObservedHorizontal
        dateRecovered
        userRecovered
        dateSubmitted
        userSubmitted
        localCoordinates
        vertical {
          surveyComputationalMethod
          accuracy
          accuracyNSRSVertical
          epoch
          geoid
          datum
          units
          orthometricHeight
          ellipsoidalHeight
        }
        owner
        isBoundaryMonument
        isMagnetic
        isGPSSuitable
        physicalAccess
        isObstructedNorth
        isObstructedSouth
        isObstructedEast
        isObstructedWest
        district
        armyInstallation
        state
        county
        trs
        nearestTown
        nearestHighway
        usgsQuad
        horizontal {
          surveyComputationalMethod
          accuracyNSRSHorizontal
          accuracy
          epoch
          datum
          hemisphereEW
          hemisphereNS
          latitude {
            degrees
            minutes
            seconds
          }
          longitude {
            degrees
            minutes
            seconds
          }
        }
        point {
          type
          coordinates
        }
        gage {
          id
          siteId
          name
          owner
          elevation
          datum
          epoch
          comments
        }
        documents {
          id
          name
          type
          description
          mimeType
          url
          comments
        }
        projects {
          id
          name
          isCPN
          district
          cpnId
        }
      }
    }
  `,
  variables: {
    coePid: e,
    versions: t
  }
}), kr = (e, t, n, r) => ({
  query: f`
    query Query(
      $poly: InputPolygon
      $status: String
      $accessLevel: String
      $filters: [Filter]
    ) {
      ControlPointsCount(
        poly: $poly
        status: $status
        accessLevel: $accessLevel
        filters: $filters
      )
    }
  `,
  variables: {
    poly: e,
    status: t,
    accessLevel: n,
    filters: r
  }
}), Ur = (e, t, n, r, i, o) => ({
  query: f`
    query Query(
      $limit: Int
      $poly: InputPolygon
      $status: String
      $offset: Int
      $accessLevel: String
      $filters: [Filter]
    ) {
      ControlPoints(
        limit: $limit
        offset: $offset
        poly: $poly
        status: $status
        accessLevel: $accessLevel
        filters: $filters
      ) {
        nsrsPid
        status
        id
        coePid
        fiuoType
        type
        designation
        stamping
        setting
        condition
        stability
        comments
        legacyData
        dateLineage
        userLineage
        dateObservedVertical
        dateObservedHorizontal
        dateRecovered
        userRecovered
        dateSubmitted
        userSubmitted
        localCoordinates
        vertical {
          surveyComputationalMethod
          accuracy
          epoch
          datum
          units
          orthometricHeight
          ellipsoidalHeight
        }
        owner
        isBoundaryMonument
        isMagnetic
        isGPSSuitable
        physicalAccess
        isObstructedNorth
        isObstructedSouth
        isObstructedEast
        isObstructedWest
        district
        armyInstallation
        state
        county
        trs
        nearestTown
        nearestHighway
        usgsQuad
        horizontal {
          surveyComputationalMethod
          accuracyNSRSHorizontal
          accuracy
          epoch
          datum
          hemisphereEW
          hemisphereNS
          latitude {
            degrees
            minutes
            seconds
          }
          longitude {
            degrees
            minutes
            seconds
          }
        }
        point {
          type
          coordinates
        }
      }
    }
  `,
  variables: {
    limit: e,
    poly: n,
    status: r,
    offset: t,
    accessLevel: i,
    filters: o
  }
}), Vr = (e) => ({
  query: f`
    query CheckWarning($id: ID!) {
      CheckWarning(id: $id) {
        referenceId
        type
        priorObserved
        currentObserved
        reason
      }
    }
  `,
  variables: {
    id: e
  }
}), jr = (e) => ({
  mutation: f`
    mutation ApproveControlPoint($id: ID!) {
      ApproveControlPoint(id: $id) {
        nsrsPid
        status
        id
        coePid
        fiuoType
        type
        designation
        stamping
        setting
        condition
        stability
        comments
        legacyData
        dateLineage
        userLineage
        dateObservedVertical
        dateObservedHorizontal
        dateRecovered
        userRecovered
        dateSubmitted
        userSubmitted
        localCoordinates
        vertical {
          surveyComputationalMethod
          accuracy
          epoch
          datum
          units
          orthometricHeight
          ellipsoidalHeight
        }
        owner
        isBoundaryMonument
        isMagnetic
        isGPSSuitable
        physicalAccess
        isObstructedNorth
        isObstructedSouth
        isObstructedEast
        isObstructedWest
        district
        armyInstallation
        state
        county
        trs
        nearestTown
        nearestHighway
        usgsQuad
        horizontal {
          surveyComputationalMethod
          accuracyNSRSHorizontal
          accuracy
          epoch
          datum
          hemisphereEW
          hemisphereNS
          latitude {
            degrees
            minutes
            seconds
          }
          longitude {
            degrees
            minutes
            seconds
          }
        }
        point {
          type
          coordinates
        }
      }
    }
  `,
  variables: {
    id: e
  }
}), Br = (e) => ({
  mutation: f`
    mutation RejectControlPoint($id: ID!) {
      RejectControlPoint(id: $id) {
        nsrsPid
        status
        id
        coePid
        fiuoType
        type
        designation
        stamping
        setting
        condition
        stability
        comments
        legacyData
        dateLineage
        userLineage
        dateObservedVertical
        dateObservedHorizontal
        dateRecovered
        userRecovered
        dateSubmitted
        userSubmitted
        localCoordinates
        vertical {
          surveyComputationalMethod
          accuracy
          epoch
          datum
          units
          orthometricHeight
          ellipsoidalHeight
        }
        owner
        isBoundaryMonument
        isMagnetic
        isGPSSuitable
        physicalAccess
        isObstructedNorth
        isObstructedSouth
        isObstructedEast
        isObstructedWest
        district
        armyInstallation
        state
        county
        trs
        nearestTown
        nearestHighway
        usgsQuad
        horizontal {
          surveyComputationalMethod
          accuracyNSRSHorizontal
          accuracy
          epoch
          datum
          hemisphereEW
          hemisphereNS
          latitude {
            degrees
            minutes
            seconds
          }
          longitude {
            degrees
            minutes
            seconds
          }
        }
        point {
          type
          coordinates
        }
      }
    }
  `,
  variables: {
    id: e
  }
}), $r = (e) => ({
  mutation: f`
    mutation DeleteControlPoint($id: ID!) {
      DeleteControlPoint(id: $id) {
        nsrsPid
        status
        id
        coePid
        fiuoType
        type
        designation
        stamping
        setting
        condition
        stability
        comments
        legacyData
        dateLineage
        userLineage
        dateObservedVertical
        dateObservedHorizontal
        dateRecovered
        userRecovered
        dateSubmitted
        userSubmitted
        localCoordinates
        vertical {
          surveyComputationalMethod
          accuracy
          epoch
          datum
          units
          orthometricHeight
          ellipsoidalHeight
        }
        owner
        isBoundaryMonument
        isMagnetic
        isGPSSuitable
        physicalAccess
        isObstructedNorth
        isObstructedSouth
        isObstructedEast
        isObstructedWest
        district
        armyInstallation
        state
        county
        trs
        nearestTown
        nearestHighway
        usgsQuad
        horizontal {
          surveyComputationalMethod
          accuracyNSRSHorizontal
          accuracy
          epoch
          datum
          hemisphereEW
          hemisphereNS
          latitude {
            degrees
            minutes
            seconds
          }
          longitude {
            degrees
            minutes
            seconds
          }
        }
        point {
          type
          coordinates
        }
      }
    }
  `,
  variables: {
    id: e
  }
}), Hr = {
  createControlPoint: Rr,
  createControlPointFromNSRS: xr,
  updateControlPoint: Lr,
  archiveControlPoint: wr,
  approveControlPoint: jr,
  rejectControlPoint: Br,
  deleteControlPoint: $r,
  getControlPoint: Mr,
  getControlPoints: Ur,
  getControlPointsCount: kr,
  getControlPointVersions: Fr,
  checkWarning: Vr
}, Gr = (e, t) => ({
  mutation: f`
    mutation Mutation($file: Upload!, $attrs: InputUploadAttributes) {
      singleUpload(file: $file, attrs: $attrs) {
        id
        name
        type
        description
        mimeType
        url
        comments
      }
    }
  `,
  variables: { file: e, attrs: t }
}), zr = () => ({
  query: f`
    query DocumentQuery {
      Documents {
        id
        name
        type
        description
        mimeType
        url
        comments
      }
    }
  `
}), qr = (e) => ({
  query: f`
    query Query($documentId: String) {
      Document(id: $documentId) {
        id
        name
        type
        description
        mimeType
        url
        comments
      }
    }
  `,
  variables: {
    documentId: e
  }
}), Wr = {
  queryDocuments: zr,
  createDocument: Gr,
  getDocument: qr
}, Qr = (e) => ({
  mutation: f`
    mutation Mutation($createGage: InputGage) {
      CreateGage(gage: $createGage) {
        id
        siteId
        name
        owner
        datum
        elevation
        epoch
        comments
      }
    }
  `,
  variables: { createGage: e }
}), Yr = (e) => ({
  query: f`
    query Query($gageId: String) {
      Gage(id: $gageId) {
        id
        siteId
        name
        owner
        datum
        elevation
        epoch
        comments
      }
    }
  `,
  variables: {
    gageId: e
  }
}), Jr = () => ({
  query: f`
    query GetGages {
      Gages {
        id
        siteId
        name
        owner
        datum
        elevation
        epoch
        comments
      }
    }
  `
}), Xr = { getGage: Yr, getGages: Jr, createGage: Qr }, Zr = (e) => ({
  mutation: f`
    mutation Mutation($project: InputProject) {
      CreateProject(project: $project) {
        id
        name
        isCPN
        district
        cpnId
      }
    }
  `,
  variables: {
    project: e
  }
}), Kr = (e = "ALL", t = !1, n = 500) => ({
  query: f`
    query Query($type: ProjectType!, $writableOnly: Boolean!, $limit: Int!) {
      Projects(type: $type, writableOnly: $writableOnly, limit: $limit) {
        id
        name
        isCPN
        district
        cpnId
      }
    }
  `,
  variables: {
    type: e,
    writableOnly: t,
    limit: n
  }
}), ei = (e) => ({
  query: f`
    query Query($id: String!) {
      Project(id: $id) {
        id
        name
        isCPN
        district
        cpnId
      }
    }
  `,
  variables: {
    id: e
  }
}), ti = (e) => ({
  query: f`
    query Query($id: String!) {
      ProjectControlPoints(id: $id) {
        id
        coePid
        designation
      }
    }
  `,
  variables: {
    id: e
  }
}), ni = (e, t) => ({
  mutation: f`
    mutation MergeProjects($usmart: ID!, $cpn: ID!) {
      MergeProjects(usmart: $usmart, cpn: $cpn)
    }
  `,
  variables: {
    usmart: e,
    cpn: t
  }
}), ri = (e) => ({
  mutation: f`
    mutation DeleteProject($id: ID!) {
      DeleteProject(id: $id)
    }
  `,
  variables: { id: e }
}), ii = {
  createProject: Zr,
  getAll: Kr,
  get: ei,
  merge: ni,
  remove: ri,
  projectControlPoints: ti
}, oi = {
  document: Wr,
  gage: Xr,
  controlPoint: Hr,
  association: _r,
  project: ii
}, si = f`
  type ControlPointAssociation {
    primaryId: String!
    localId: String!
    comments: String
  }

  input InputControlPointAssociation {
    primaryId: String!
    localId: String!
    comments: String
  }

  extend type Query {
    ControlPointAssociations: [ControlPointAssociation]
    ControlPointAssociationsMany(
      primaries: [String]
      locals: [String]
    ): [ControlPointAssociation]
    ControlPointAssociation(primaryId: String): [ControlPointAssociation]
    ControlPointAssociationByLocal(localId: String): [ControlPointAssociation]
  }

  extend type Mutation {
    CreateControlPointAssociation(
      controlPointAssociation: InputControlPointAssociation
    ): ControlPointAssociation @auth(checks: AUTHENTICATED)
    AssociationUpdateControlPointAssociations(
      localId: String
      primaryIds: [String]
    ): [ControlPointAssociation] @auth(checks: AUTHENTICATED)
  }
`, ai = f`
  scalar Date

  enum Status {
    Approved
    Archived
    Deleted
    Submitted
    Rejected
  }

  enum FIUOType {
    CAC
    Manager
    None
  }

  enum Type {
    Local
    Primary
  }

  enum Stability {
    A
    B
    C
    D
  }

  type DMS {
    degrees: String
    minutes: String
    seconds: String
  }

  input InputDMS {
    degrees: String
    minutes: String
    seconds: String
  }

  enum NS {
    North
    South
  }

  enum EW {
    East
    West
  }

  type Horizontal {
    longitude: DMS!
    latitude: DMS!
    hemisphereNS: NS!
    hemisphereEW: EW!
    datum: String
    epoch: String
    accuracy: String
    accuracyNSRSHorizontal: String
    surveyComputationalMethod: String
  }

  input InputHorizontal {
    longitude: InputDMS!
    latitude: InputDMS!
    hemisphereNS: NS!
    hemisphereEW: EW!
    datum: String
    epoch: String
    accuracy: String
    accuracyNSRSHorizontal: String
    surveyComputationalMethod: String
  }

  type Document {
    id: String
    coePid: String
    name: String
    type: String
    description: String
    mimeType: String
    url: String
    comments: String
  }

  input InputDocument {
    id: String
    coePid: String
    name: String
    type: String
    description: String
    mimeType: String
    url: String
    comments: String
  }

  type Gage {
    id: String
    coePid: String
    siteId: String
    name: String
    owner: String
    elevation: Float
    datum: String
    epoch: String
    comments: String
  }

  input InputGage {
    id: String
    coePid: String
    siteId: String
    name: String
    owner: String
    elevation: Float
    datum: String
    epoch: String
    comments: String
  }

  enum Units {
    Feet
    Meters
  }

  type Vertical {
    ellipsoidalHeight: Float
    orthometricHeight: Float
    units: Units
    datum: String
    geoid: String
    epoch: String
    accuracy: String
    accuracyNSRSVertical: String
    surveyComputationalMethod: String
  }

  input InputVertical {
    ellipsoidalHeight: Float
    orthometricHeight: Float
    units: Units
    datum: String
    geoid: String
    epoch: String
    accuracy: String
    accuracyNSRSVertical: String
    surveyComputationalMethod: String
  }

  type Geometry {
    coordinates: Float
  }

  type Point {
    type: String
    coordinates: [Float]
  }

  input InputPoint {
    type: String!
    coordinates: [Float]
  }

  type Polygon {
    type: String
    coordinates: [[[Float]]]
  }

  input InputPolygon {
    type: String
    coordinates: [[[Float]]]
  }

  input Filter {
    key: String
    op: String
    val: String
    valType: String
  }

  # if we put @auth on the type, it's a little easier to secure, but it adds _significant_ overhead to bulk queries.
  # instead, add @auth to the Query/Mutation. This ensures the check runs only once.
  type ControlPoint {
    id: String
    coePid: String!
    status: String!
    type: Type!
    district: String!
    designation: String!
    dateLineage: Date!
    point: Point!
    userLineage: String!
    horizontal: Horizontal!
    nsrsPid: String
    fiuoType: FIUOType
    project: String
    stamping: String
    setting: String
    condition: String
    stability: Stability
    owner: String
    isBoundaryMonument: Boolean
    isMagnetic: Boolean
    isGPSSuitable: Boolean
    physicalAccess: String
    isObstructedNorth: Boolean
    isObstructedSouth: Boolean
    isObstructedEast: Boolean
    isObstructedWest: Boolean
    armyInstallation: String
    state: String
    county: String
    trs: String
    nearestTown: String
    nearestHighway: String
    usgsQuad: String
    vertical: Vertical
    localCoordinates: String
    userSubmitted: String
    dateSubmitted: Date
    userRecovered: String
    dateRecovered: Date
    dateObservedHorizontal: Date
    dateObservedVertical: Date
    comments: String
    legacyData: String
    documents: [Document]
    gage: Gage
    projects: [Project]
  }

  input InputControlPoint {
    id: String
    coePid: String!
    status: String!
    designation: String!
    userLineage: String!
    dateLineage: Date!
    point: InputPoint!
    district: String!
    type: Type!
    horizontal: InputHorizontal!
    nsrsPid: String
    fiuoType: FIUOType
    project: String
    stamping: String
    setting: String
    condition: String
    stability: Stability
    owner: String
    isBoundaryMonument: Boolean
    isMagnetic: Boolean
    isGPSSuitable: Boolean
    physicalAccess: String
    isObstructedNorth: Boolean
    isObstructedSouth: Boolean
    isObstructedEast: Boolean
    isObstructedWest: Boolean
    armyInstallation: String
    state: String
    county: String
    trs: String
    nearestTown: String
    nearestHighway: String
    usgsQuad: String
    vertical: InputVertical
    localCoordinates: String
    userSubmitted: String
    dateSubmitted: Date
    userRecovered: String
    dateRecovered: Date
    dateObservedHorizontal: Date
    dateObservedVertical: Date
    comments: String
    gage: InputGage
    documents: [InputDocument]
    legacyData: String
    projects: [InputProject]
  }

  enum WarningType {
    OPUS
    NGS
    USMART
  }

  enum NSRSType {
    OPUS
    NGS
  }

  type Warning {
    referenceId: String
    type: WarningType
    priorObserved: Date
    currentObserved: Date
    reason: String
  }

  extend type Query {
    ControlPointsCount(
      poly: InputPolygon
      status: String
      accessLevel: String
      filters: [Filter]
    ): Int
    ControlPoints(
      limit: Int = 200
      offset: Int = 0
      poly: InputPolygon
      status: String
      accessLevel: String
      filters: [Filter]
    ): [ControlPoint] # for performance, authz has been moved to the resolver
    ControlPoint(id: String!): ControlPoint #@auth(checks: FIUO) move to resolver
    ControlPointVersions(coePid: String!, versions: Int): [ControlPoint]
      @auth(checks: FIUO)
    CheckWarning(id: ID!): [Warning] @auth(checks: AUTHENTICATED)
    Sync(aoi: InputPolygon, lastSync: String, history: Boolean): [ControlPoint]
      @auth(checks: AUTHENTICATED)
  }

  extend type Mutation {
    CreateControlPointFromNSRS(
      cp: InputControlPoint
      type: NSRSType
      pid: String
    ): ControlPoint
      @auth(
        requirements: {
          district: "cp.district"
          scope: controlpoints
          accessLevel: write
        }
      )
    CreateControlPoint(cp: InputControlPoint): ControlPoint
      @auth(
        requirements: {
          district: "cp.district"
          scope: controlpoints
          accessLevel: write
        }
      )
    UpdateControlPoint(cp: InputControlPoint): ControlPoint
      @auth(
        requirements: {
          district: "cp.district"
          scope: controlpoints
          accessLevel: write
        }
      )
    ArchiveControlPoint(id: ID!): ControlPoint
      @auth(requirements: { scope: controlpoints, accessLevel: manage })
    RejectControlPoint(id: ID!): ControlPoint
      @auth(requirements: { scope: controlpoints, accessLevel: manage })
    ApproveControlPoint(id: ID!): ControlPoint
      @auth(requirements: { scope: controlpoints, accessLevel: manage })
    DeleteControlPoint(id: ID!): ControlPoint
      @auth(requirements: { scope: controlpoints, accessLevel: manage })
  }
`, ci = f`
  # The implementation for this scalar is provided by the
  # 'GraphQLUpload' export from the 'graphql-upload' package
  # in the resolver map below.
  scalar Upload

  type File {
    filename: String!
    mimetype: String!
    encoding: String!
  }

  type Document {
    id: String
    name: String
    type: String
    description: String
    mimeType: String
    url: String
    comments: String
  }

  input InputDocument {
    id: String
    name: String
    type: String
    description: String
    mimeType: String
    url: String
    comments: String
  }

  input InputUploadAttributes {
    id: String
    name: String
    description: String
    comments: String
    type: String
    controlPointId: String!
    mimeType: String!
  }

  extend type Query {
    Documents: [Document]
    Document(id: String): Document
    otherFields: Boolean!
  }

  extend type Mutation {
    CreateDocument(doc: InputDocument): Document @auth(checks: AUTHENTICATED)
    singleUpload(file: Upload!, attrs: InputUploadAttributes): Document!
      @auth(checks: AUTHENTICATED)
  }
`, ui = f`
  type Gage {
    id: String
    siteId: String
    name: String
    owner: String
    datum: String
    elevation: Float
    epoch: String
    comments: String
  }

  input InputGage {
    id: String
    siteId: String
    name: String
    owner: String
    datum: String
    elevation: Float
    epoch: String
    comments: String
  }

  extend type Query {
    Gages: [Gage]
    Gage(id: String): Gage
  }

  extend type Mutation {
    CreateGage(gage: InputGage): Gage
  }
`, li = f`
  type Project {
    id: String!
    name: String!
    isCPN: Boolean!
    district: String
    cpnId: String
  }

  input InputProject {
    id: String!
    name: String!
    isCPN: Boolean!
    district: String
    cpnId: String
  }

  enum ProjectType {
    ALL
    CPN
    USMART
  }

  extend type Query {
    Projects(
      type: ProjectType = ALL
      writableOnly: Boolean
      limit: Int
    ): [Project] @auth(checks: AUTHENTICATED)
    Project(id: String): Project @auth(checks: AUTHENTICATED)
    ProjectControlPoints(id: String): [ControlPoint]
    # @auth(checks: AUTHENTICATED)
  }

  extend type Mutation {
    MergeProjects(usmart: ID!, cpn: ID!): Boolean @auth(checks: ADMIN)
    CreateProject(project: InputProject): Project @auth(checks: ADMIN)
    DeleteProject(id: ID!): Boolean @auth(checks: ADMIN)
  }
`, di = { association: si, controlPoint: ai, document: ci, gage: ui, project: li };
var pi = /* @__PURE__ */ ((e) => (e.NOT_AUTHENTICATED = "Not Authenticated", e.NOT_AUTHORIZED = "Not Authorized", e.NOT_FOUND = "Not Found", e))(pi || {}), hi = /* @__PURE__ */ ((e) => (e.admin = "admin", e.controlPoints = "controlpoints", e))(hi || {}), fi = /* @__PURE__ */ ((e) => (e.read = "read", e.write = "write", e.manage = "manage", e))(fi || {}), mi = /* @__PURE__ */ ((e) => (e.ORG = "org", e.DIVISION = "division", e.DISTRICT = "district", e))(mi || {}), gi = /* @__PURE__ */ ((e) => (e.AUTHENTICATED = "AUTHENTICATED", e.ANONYMOUS = "ANONYMOUS", e.INTERNAL = "INTERNAL", e.CONTRACTOR = "CONTRACTOR", e.SELF_USER = "SELF_USER", e.FIUO = "FIUO", e.ADMIN = "ADMIN", e.ROLE_AUTH = "ROLE_AUTH", e))(gi || {});
const ki = (e, t, n, r, i) => {
  let o = "";
  switch (r) {
    case "users":
      o += "User";
      break;
    case "roles":
      o += "Role";
      break;
    case "controlpoints":
      o += "Control Point";
      break;
    case "reports":
      o += "Report";
      break;
    case "admin":
      o += "Administrator";
  }
  if (r !== "admin")
    switch (i) {
      case "read":
        o += " Viewer";
        break;
      case "write":
        o += " Editor";
        break;
      case "manage":
        o += " Manager";
        break;
    }
  return n ? o += ` - ${n.toUpperCase()}` : t ? o += ` - ${t.toUpperCase()}` : e && (o += ` - ${e.toUpperCase()}`), o;
}, Ui = (e) => {
  switch (e) {
    case "manage":
      return 2;
    case "write":
      return 1;
    case "read":
      return 0;
    default:
      return -1;
  }
}, Vi = "global.admin.manage", ji = "cac";
var ge, yi = new Uint8Array(16);
function vi() {
  if (!ge && (ge = typeof crypto < "u" && crypto.getRandomValues && crypto.getRandomValues.bind(crypto) || typeof msCrypto < "u" && typeof msCrypto.getRandomValues == "function" && msCrypto.getRandomValues.bind(msCrypto), !ge))
    throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
  return ge(yi);
}
const Si = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i;
function Ti(e) {
  return typeof e == "string" && Si.test(e);
}
var b = [];
for (var Pe = 0; Pe < 256; ++Pe)
  b.push((Pe + 256).toString(16).substr(1));
function Ni(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0, n = (b[e[t + 0]] + b[e[t + 1]] + b[e[t + 2]] + b[e[t + 3]] + "-" + b[e[t + 4]] + b[e[t + 5]] + "-" + b[e[t + 6]] + b[e[t + 7]] + "-" + b[e[t + 8]] + b[e[t + 9]] + "-" + b[e[t + 10]] + b[e[t + 11]] + b[e[t + 12]] + b[e[t + 13]] + b[e[t + 14]] + b[e[t + 15]]).toLowerCase();
  if (!Ti(n))
    throw TypeError("Stringified UUID is invalid");
  return n;
}
function Ii(e, t, n) {
  e = e || {};
  var r = e.random || (e.rng || vi)();
  return r[6] = r[6] & 15 | 64, r[8] = r[8] & 63 | 128, Ni(r);
}
const H = () => Ii(), w = (e = 8) => {
  const t = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+=-";
  let n = "";
  for (let r = 0; r < e; r++)
    n += t.charAt(Math.floor(Math.random() * t.length));
  return n;
}, Ai = () => `${Rt}${w(14)}`, _ = (e = 8) => {
  if (!(Math.random() < 0.5))
    return w(e);
}, Nt = () => I([
  "NAD83(2011)",
  "NAD83(NSRS2007)",
  "NAD83(FBN)",
  "NAD83(HARN)",
  "NAD83(1986)",
  "NAD27",
  "WGS84"
]), Ei = () => I([
  "ASVD02",
  "LMSL",
  "IGLD85",
  "IGLD95",
  "MSL 1912",
  "MSL",
  "NAVD88 (2010)",
  "NAVD88"
]), Oi = () => ({
  id: H(),
  name: w(),
  owner: Di(),
  elevation: we(),
  datum: Nt(),
  epoch: q().toString(),
  comments: w()
}), Bi = (e = void 0) => ({
  id: H(),
  name: w(),
  isCPN: e ?? B(),
  district: It(),
  cpnId: w()
}), Le = () => ({
  id: H(),
  name: w(),
  type: At(),
  description: w(),
  mimeType: w(),
  url: w(),
  comments: w()
});
function I(e) {
  return e[Math.floor(Math.random() * e.length)];
}
const $i = () => ({
  ...Le(),
  type: At(),
  controlPointId: H()
}), B = () => I([!0, !1]);
function Ci(e) {
  const t = Object.keys(e), n = Math.floor(Math.random() * t.length);
  return t[n];
}
const ue = (e = new Date(2e3, 1, 1), t = /* @__PURE__ */ new Date()) => new Date(e.getTime() + Math.random() * (t.getTime() - e.getTime())), we = (e = 100) => Math.random() * e, q = (e = 100) => Math.floor(Math.random() * e), tt = () => I([
  "1-mm",
  "2-mm",
  "5-mm",
  "1-cm",
  "2-cm",
  "5-cm",
  "1-dm",
  "2-dm",
  "5-dm",
  "1-m",
  "2-m",
  "5-m",
  "10-m+"
]), bi = () => I([
  "Fort A P Hill",
  "Fort Belvoir",
  "Fort Benning AL",
  "Fort Benning GA",
  "Fort Bliss AAA Ranges",
  "Fort Bliss",
  "Fort Bragg",
  "Fort Campbell KY",
  "Fort Campbell TN",
  "Fort Carson",
  "Fort De Russy",
  "Fort Detrick",
  "Fort Drum",
  "Fort Eustis",
  "Fort George G Meade",
  "Fort Gillem",
  "Fort Gordon Recreation Area",
  "Fort Gordon",
  "Fort Greely",
  "Fort Hamilton",
  "Fort Hood",
  "Fort Huachuca",
  "Fort Jackson",
  "Fort Knox",
  "Fort Leavenworth",
  "Fort Lee",
  "Fort Leonard Wood",
  "Fort Lesley J McNair",
  "Fort Lewis",
  "Fort McPherson",
  "Fort Monmouth Evans",
  "Fort Monmouth Main Post",
  "Fort Monroe",
  "Fort Myer",
  "Fort Ord",
  "Fort Polk",
  "Fort Richardson",
  "Fort Riley",
  "Fort Rucker",
  "Fort Sam Houston",
  "Fort Shafter",
  "Fort Sill",
  "Fort Stewart",
  "Fort Wainwright",
  "Fort Wingate Depot Activity"
]), It = () => I([
  "ARMY",
  "HQ",
  "JALBTCX",
  "LRB",
  "LRC",
  "LRD",
  "LRE",
  "LRH",
  "LRL",
  "LRN",
  "LRP",
  "MVD",
  "MVK",
  "MVM"
]), Hi = () => ({
  id: H(),
  coePid: Ai(),
  nsrsPid: _(),
  fiuoType: I([ee.CAC, ee.Manager, ee.None]),
  status: I([
    $.Archived,
    $.Deleted,
    $.Deleted,
    $.Rejected,
    $.Approved
  ]),
  type: I(["Local", "Primary"]),
  designation: w(),
  stamping: _(),
  setting: _(),
  condition: I([
    "Destroyed",
    "Good",
    "Monumented",
    "Not recovered",
    "Poor, Disturbed"
  ]),
  stability: I(["A", "B", "C", "D"]),
  owner: _(),
  isBoundaryMonument: B(),
  isMagnetic: B(),
  isGPSSuitable: B(),
  physicalAccess: _(),
  isObstructedNorth: B(),
  isObstructedSouth: B(),
  isObstructedEast: B(),
  isObstructedWest: B(),
  district: It(),
  armyInstallation: bi(),
  state: Ci(bt),
  county: _(),
  trs: _(),
  nearestTown: _(),
  nearestHighway: _(),
  usgsQuad: _(),
  horizontal: {
    longitude: {
      degrees: q(179).toString(),
      minutes: q(60).toString(),
      seconds: q(60).toString()
    },
    latitude: {
      degrees: q(89).toString(),
      minutes: q(60).toString(),
      seconds: q(60).toString()
    },
    hemisphereNS: I(["North", "South"]),
    hemisphereEW: I(["East", "West"]),
    datum: Nt(),
    epoch: _(),
    accuracy: tt(),
    accuracyNSRSHorizontal: I([
      "1-cm",
      "2-cm",
      "5-cm",
      "1-dm",
      "2-dm",
      "5-dm",
      "2'",
      "1-m",
      "2-m",
      "5-m",
      "10-m+"
    ]),
    surveyComputationalMethod: w()
  },
  vertical: {
    ellipsoidalHeight: we(),
    orthometricHeight: we(),
    units: I(["Feet", "Meters"]),
    datum: Ei(),
    geoid: I([
      "EGM1996",
      "EGM2008",
      "Geoid03 (2005)",
      "Geoid03",
      "Geoid06",
      "Geoid09",
      "Geoid12",
      "Geoid12A",
      "Geoid12B",
      "Geoid18",
      "Geoid96",
      "Geoid99"
    ]),
    epoch: _(),
    accuracy: tt(),
    accuracyNSRSVertical: I([
      "1-cm",
      "2-cm",
      "5-cm",
      "0.25'",
      "1-dm",
      "2-dm",
      "5-dm",
      "1-m",
      "2-m",
      "5-m",
      "10-m+"
    ])
  },
  localCoordinates: _(),
  userSubmitted: H(),
  dateSubmitted: ue(),
  userRecovered: H(),
  dateRecovered: ue(),
  dateObservedHorizontal: ue(),
  dateObservedVertical: ue(),
  userLineage: H(),
  dateLineage: ue(),
  point: {
    type: "Point",
    coordinates: [
      I(Ce(-124, -70)),
      I(Ce(27, 49)),
      I(Ce(0, 1e3))
    ]
  },
  comments: _(),
  legacyData: _(),
  gage: Oi(),
  documents: [Le(), Le()]
}), Di = () => I(["NOAA", "USACE", "USGS"]), At = () => I(["Close-up View", "Horizontal View", "Map Overview", "Other"]), Gi = di, zi = oi, le = (e) => typeof e != "string" ? e == null ? void 0 : e.toISOString() : e, Et = P({
  type: T("Point"),
  coordinates: pe(J)
}), nt = P({
  degrees: s,
  minutes: s,
  seconds: s
}), Pi = F({ North: null, South: null }), _i = F({ East: null, West: null }), Ot = z([
  P({
    longitude: nt,
    latitude: nt,
    hemisphereNS: Pi,
    hemisphereEW: _i
  }),
  V({
    datum: s,
    epoch: s,
    accuracy: s,
    accuracyNSRSHorizontal: s,
    surveyComputationalMethod: s
  })
]), Ct = V({
  ellipsoidalHeight: J,
  orthometricHeight: J,
  units: Y([T("Feet"), T("Meters")]),
  datum: s,
  geoid: s,
  epoch: s,
  accuracy: s,
  accuracyNSRSVertical: s,
  surveyComputationalMethod: s
});
var bt = /* @__PURE__ */ ((e) => (e.AK = "AK", e.AL = "AL", e.AR = "AR", e.AS = "AS", e.AZ = "AZ", e.CA = "CA", e.CO = "CO", e.CT = "CT", e.DC = "DC", e.DE = "DE", e.FL = "FL", e.GA = "GA", e.GU = "GU", e.HI = "HI", e.IA = "IA", e.ID = "ID", e.IL = "IL", e.IN = "IN", e.KS = "KS", e.KY = "KY", e.LA = "LA", e.MA = "MA", e.MD = "MD", e.ME = "ME", e.MI = "MI", e.MN = "MN", e.MO = "MO", e.MS = "MS", e.MT = "MT", e.NC = "NC", e.ND = "ND", e.NE = "NE", e.NH = "NH", e.NJ = "NJ", e.NM = "NM", e.NV = "NV", e.NY = "NY", e.OH = "OH", e.OK = "OK", e.OR = "OR", e.PA = "PA", e.PR = "PR", e.RI = "RI", e.SC = "SC", e.SD = "SD", e.TN = "TN", e.TX = "TX", e.UT = "UT", e.VA = "VA", e.VI = "VI", e.VT = "VT", e.WA = "WA", e.WI = "WI", e.WV = "WV", e.WY = "WY", e))(bt || {});
const Dt = F({
  AK: null,
  AL: null,
  AR: null,
  AS: null,
  AZ: null,
  CA: null,
  CO: null,
  CT: null,
  DC: null,
  DE: null,
  FL: null,
  GA: null,
  GU: null,
  HI: null,
  IA: null,
  ID: null,
  IL: null,
  IN: null,
  KS: null,
  KY: null,
  LA: null,
  MA: null,
  MD: null,
  ME: null,
  MI: null,
  MN: null,
  MO: null,
  MS: null,
  MT: null,
  NC: null,
  ND: null,
  NE: null,
  NH: null,
  NJ: null,
  NM: null,
  NV: null,
  NY: null,
  OH: null,
  OK: null,
  OR: null,
  PA: null,
  PR: null,
  RI: null,
  SC: null,
  SD: null,
  TN: null,
  TX: null,
  UT: null,
  VA: null,
  VI: null,
  VT: null,
  WA: null,
  WI: null,
  WV: null,
  WY: null
});
var $ = /* @__PURE__ */ ((e) => (e.Approved = "Approved", e.Archived = "Archived", e.Deleted = "Deleted", e.Submitted = "Submitted", e.Rejected = "Rejected", e))($ || {}), ee = /* @__PURE__ */ ((e) => (e.CAC = "CAC", e.Manager = "Manager", e.None = "None", e))(ee || {});
const Pt = z([
  P({
    id: k,
    name: s,
    isCPN: x
  }),
  V({
    district: s,
    cpnId: s
  })
]), _t = z([
  P({
    id: k
  }),
  V({
    siteId: s,
    name: s,
    owner: s,
    elevation: J,
    datum: s,
    epoch: s,
    comments: s
  })
]), xt = z([
  P({
    id: k,
    name: s,
    url: s,
    type: s
  }),
  V({
    description: s,
    mimeType: s,
    comments: s
  })
]), qi = z([
  P({
    id: k,
    coePid: s,
    status: F($),
    type: Y([T("Local"), T("Primary")]),
    district: s,
    designation: s,
    dateLineage: Q,
    point: Et,
    userLineage: k,
    horizontal: Ot
  }),
  V({
    vertical: Ct,
    nsrsPid: s,
    fiuoType: F(ee),
    stamping: s,
    setting: s,
    condition: Y([
      T("Destroyed"),
      T("Good"),
      T("Monumented"),
      T("Not recovered"),
      T("Poor, Disturbed")
    ]),
    stability: Y([
      T("A"),
      T("B"),
      T("C"),
      T("D")
    ]),
    owner: s,
    isBoundaryMonument: x,
    isMagnetic: x,
    isGPSSuitable: x,
    physicalAccess: s,
    isObstructedNorth: x,
    isObstructedSouth: x,
    isObstructedEast: x,
    isObstructedWest: x,
    armyInstallation: s,
    //TODO Make resolver for this
    state: Dt,
    county: s,
    trs: s,
    nearestTown: s,
    nearestHighway: s,
    usgsQuad: s,
    localCoordinates: s,
    userSubmitted: s,
    dateSubmitted: Q,
    userRecovered: s,
    dateRecovered: Q,
    dateObservedHorizontal: Q,
    dateObservedVertical: Q,
    comments: s,
    legacyData: s,
    gage: _t,
    documents: pe(xt),
    projects: pe(Pt)
  })
]), xi = F({
  Local: null,
  Primary: null
}), Wi = z([
  P({
    id: k,
    coePid: s,
    status: F($),
    type: xi,
    district: s,
    designation: s,
    dateLineage: s,
    point: Et,
    userLineage: k,
    horizontal: Ot
  }),
  V({
    vertical: Ct,
    nsrsPid: s,
    fiuoType: F(ee),
    stamping: s,
    setting: s,
    condition: Y([
      T("Destroyed"),
      T("Good"),
      T("Monumented"),
      T("Not recovered"),
      T("Poor, Disturbed")
    ]),
    stability: Y([
      T("A"),
      T("B"),
      T("C"),
      T("D")
    ]),
    owner: s,
    isBoundaryMonument: x,
    isMagnetic: x,
    isGPSSuitable: x,
    physicalAccess: s,
    isObstructedNorth: x,
    isObstructedSouth: x,
    isObstructedEast: x,
    isObstructedWest: x,
    armyInstallation: s,
    state: Dt,
    county: s,
    trs: s,
    nearestTown: s,
    nearestHighway: s,
    usgsQuad: s,
    localCoordinates: s,
    userSubmitted: s,
    userRecovered: s,
    dateSubmitted: s,
    dateRecovered: s,
    dateObservedHorizontal: s,
    dateObservedVertical: s,
    comments: s,
    legacyData: s,
    gage: _t,
    documents: pe(xt),
    projects: pe(Pt)
  })
]), Rt = "_", Qi = (e) => e.charAt(0) === Rt, Yi = z([
  P({
    localId: k,
    primaryId: k
  }),
  V({
    comments: s
  })
]), Ji = z([
  P({
    id: k,
    name: s,
    type: s,
    controlPointId: k
  }),
  V({
    description: s,
    comments: s,
    mimeType: s,
    url: s
  })
]);
function Ue(e) {
  const t = ae(
    (o) => !se(o),
    e.horizontal
  ), n = ae(
    (o) => !se(o),
    e.vertical
  ), r = ae((o) => !se(o), e.gage), i = ae((o) => !se(o), e.point);
  return {
    // remove all null values
    ...ae((o) => !se(o), e),
    horizontal: t,
    vertical: Je(n) ? void 0 : n,
    gage: Je(r) ? void 0 : r,
    point: i,
    dateSubmitted: e.dateSubmitted ? le(e.dateSubmitted) : void 0,
    dateRecovered: e.dateRecovered ? le(e.dateRecovered) : void 0,
    dateObservedHorizontal: e.dateObservedHorizontal ? le(e.dateObservedHorizontal) : void 0,
    dateObservedVertical: e.dateObservedVertical ? le(e.dateObservedVertical) : void 0,
    dateLineage: le(e.dateLineage)
  };
}
function Xi(e) {
  var t, n, r, i;
  return {
    ...e,
    dateSubmitted: e.dateSubmitted ? new Date(e.dateSubmitted) : void 0,
    dateRecovered: e.dateRecovered ? new Date(e.dateRecovered) : void 0,
    dateObservedHorizontal: e.dateObservedHorizontal ? new Date(e.dateObservedHorizontal) : void 0,
    dateObservedVertical: e.dateObservedVertical ? new Date(e.dateObservedVertical) : void 0,
    dateLineage: new Date(e.dateLineage),
    vertical: {
      ...e.vertical,
      // foreign keyed items can't be '', must be undefined
      datum: ((t = e.vertical) == null ? void 0 : t.datum) !== "" ? (n = e.vertical) == null ? void 0 : n.datum : void 0,
      surveyComputationalMethod: ((r = e.vertical) == null ? void 0 : r.surveyComputationalMethod) !== "" ? (i = e.vertical) == null ? void 0 : i.surveyComputationalMethod : void 0
    }
  };
}
var Ri = /* @__PURE__ */ ((e) => (e.gt = ">", e.gte = ">=", e.lt = "<", e.lte = "<=", e.e = "=", e.ne = "!=", e.like = "~", e.null = "!", e))(Ri || {});
const Li = F({
  gt: null,
  gte: null,
  lt: null,
  lte: null,
  e: null,
  ne: null,
  like: null,
  null: null
});
var wi = /* @__PURE__ */ ((e) => (e.date = "date", e.string = "string", e.int = "int", e.float = "float", e.null = "null", e))(wi || {});
const Mi = F({
  date: null,
  string: null,
  int: null,
  float: null,
  null: null
}), Zi = P({
  key: s,
  op: Li,
  val: s,
  valType: Mi
}), Ki = P({
  code: s,
  parent: s,
  depth: J,
  name: s
}), eo = P({
  pid: s,
  ts: s,
  designation: s,
  stCounty: s,
  refFrame: s,
  epoch: s,
  lat: s,
  latDms: s,
  latP2p: s,
  lon: s,
  lonDms: s,
  lonP2p: s,
  ellHt: s,
  ellHtP2p: s,
  datum: s,
  orthoHt: s,
  orthoHtP2p: s,
  observed: s,
  agency: s,
  monumentType: s,
  monumentDesc: s,
  setting: s,
  specificSetting: s,
  stamping: s,
  stability: s,
  magnetic: s,
  condition: s,
  description: s
}), to = P({
  pid: s,
  name: s,
  stCounty: s,
  quad: s,
  lat: s,
  latDms: s,
  lon: s,
  lonDms: s,
  ellipHeight: s,
  posDatum: s,
  epoch: s,
  posSource: s,
  posOrder: s,
  orthoHt: s,
  vertDatum: s,
  vertSource: s,
  vertOrder: s,
  vertClass: s,
  vertEpoch: s,
  geoidHt: s,
  geoidModel: s,
  dynamicHt: s,
  modeledGravity: s,
  monumentType: s,
  stamping: s,
  setting: s,
  stability: s,
  pacsSacs: s,
  condition: s,
  lastRecovered: s,
  lastRecoveredBy: s,
  netAccHz: s,
  netAccEh: s,
  netAccN: s,
  netAccE: s,
  netAccU: s,
  netCorrNE: s,
  htMod: s,
  satUse: s,
  x: s,
  y: s,
  z: s,
  spcZone: s,
  spcNorthing: s,
  spcEasting: s,
  spcConvergence: s,
  spcCombinedFactor: s,
  utmZone: s,
  utmNorthing: s,
  utmEasting: s,
  utmConvergence: s,
  utmCombinedFactor: s
}), no = P({
  ID: s,
  nadconVersion: s,
  vertconVersion: s,
  srcDatum: s,
  destDatum: s,
  srcVertDatum: s,
  destVertDatum: s,
  srcLat: s,
  srcLatDms: s,
  destLat: s,
  destLatDms: s,
  deltaLat: s,
  sigLat: s,
  sigLat_m: s,
  srcLon: s,
  srcLonDms: s,
  destLon: s,
  destLonDms: s,
  deltaLon: s,
  sigLon: s,
  sigLon_m: s,
  heightUnits: s,
  srcEht: s,
  destEht: s,
  sigEht: s,
  srcOrthoht: s,
  destOrthoht: s,
  sigOrthoht: s,
  spcZone: s,
  spcNorthing_m: s,
  spcEasting_m: s,
  spcNorthing_usft: s,
  spcEasting_usft: s,
  spcNorthing_ift: s,
  spcEasting_ift: s,
  spcConvergence: s,
  spcScaleFactor: s,
  spcCombinedFactor: s,
  utmZone: s,
  utmNorthing: s,
  utmEasting: s,
  utmConvergence: s,
  utmScaleFactor: s,
  utmCombinedFactor: s,
  x: s,
  y: s,
  z: s,
  usng: s
}), Fi = F({ NGS: null, OPUS: null }), ro = P({
  referenceId: s,
  type: Y([Fi, T("USMART")]),
  priorObserved: Q,
  currentObserved: Q,
  reason: s
});
export {
  Vi as ADMIN_ROLE,
  fi as AccessLevel,
  gi as AuthzCheck,
  ji as CAC_ROLE,
  Rt as COE_TEMP_PREFIX,
  qi as ControlPoint,
  Yi as ControlPointAssociation,
  Wi as ControlPointSafe,
  nt as DMS,
  xt as Document,
  Ji as DocumentInput,
  pi as Errors,
  Zi as Filter,
  Li as FilterType,
  Ri as FilterTypeEnum,
  ee as Fiuo,
  _t as Gage,
  Ot as Horizontal,
  Fi as NSRSType,
  ro as NSRSWarning,
  no as NgsLlhData,
  to as NgsPoint,
  eo as OpusPoint,
  mi as OrgLevel,
  Ki as Organization,
  Et as Point,
  xi as PointType,
  Pt as Project,
  hi as Scope,
  bt as StateEnum,
  $ as Status,
  Mi as ValType,
  wi as ValTypeEnum,
  Ct as Vertical,
  Ui as accessLevelValue,
  zi as client,
  ki as createRoleDisplayName,
  Xi as deserialize,
  Qi as isTempCoePid,
  Ai as randomCoePid,
  Hi as sampleControlPoint,
  Le as sampleDocument,
  $i as sampleDocumentInput,
  Oi as sampleGage,
  Bi as sampleProject,
  Gi as schemas,
  Ue as serialize
};
